import { IconType } from 'react-icons';
import { PiWarningCircleLight } from 'react-icons/pi';
import CustomModal from './CustomModal';
import IconInfoCard from './IconInfoCard';
import Button from './Button';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  message?: string;
  icon?: IconType;
  iconColor?: string;
  confirmButtonText?: string;
  loading?: boolean;
}

const ConfirmActionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  title = '¿Estas seguro?',
  message = 'Esta acción no se puede deshacer',
  icon = PiWarningCircleLight,
  iconColor = 'text-red-600',
  confirmButtonText = 'Confirmar',
  loading = false,
}) => (
  <CustomModal
    isOpen={isOpen}
    onRequestClose={onClose}
    actionBar={
      <div className="w-full">
        <Button
          variant="danger"
          onClick={onConfirm}
          className="text-center w-full mt-2"
          loading={loading}
        >
          {confirmButtonText}
        </Button>
      </div>
    }
  >
    <IconInfoCard
      icon={icon}
      iconColor={iconColor}
      title={title}
      info={message}
    />
  </CustomModal>
);

export default ConfirmActionModal;
