/* eslint-disable no-nested-ternary */
import { HiOutlineRocketLaunch } from 'react-icons/hi2';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OrdersTableSkeleton from '../components/OrdersTableSkeleton';
import { useGetBusinessInvitationsQuery, useGetBusinessUsersQuery } from '../store';
import Button from '../components/atoms/Button';
import IconInfoCard from '../components/atoms/IconInfoCard';
import CustomModal from '../components/atoms/CustomModal';
import UsersTable from '../components/UsersTable';
import InvitationDTO from '../models/invitation';
import InvitationsTable from '../components/InvitationsTable';
import InviteUser from '../components/InviteUser';

const AdminUsersPage: React.FC = () => {
  const { business_id: businessId } = useParams<{ business_id: string }>();
  if (!businessId || !(+businessId)) {
    throw new Error('invalid business id');
  }

  const [showModal, setShowModal] = useState(false);
  const [pendingInvitations, setPendingInvitations] = useState<InvitationDTO[]>([]);

  const { data: users, isFetching: isFetchingUsers } = useGetBusinessUsersQuery({
    businessId: +businessId,
  });

  const { data: invitations, isFetching: isFetchingInvitations } = useGetBusinessInvitationsQuery({
    businessId: +businessId,
  });

  const handleSucessInviteUser = () => {
    setShowModal(false);
  };

  const handleInviteUser = () => {
    setShowModal(true);
  };

  useEffect(() => {
    setPendingInvitations(invitations?.filter((i) => i.status === 'pending') || []);
  }, [invitations]);

  return (
    <div className="px-3 h-screen">
      <h1 className="text-xl font-medium mb-4">Configuración de usuarios</h1>

      <div className="card border border-gray-200">
        <div className="flex py-2 justify-between">
          <h1 className="text-lg">Usuarios</h1>
        </div>
        {isFetchingUsers && <OrdersTableSkeleton />}{' '}
        {users && users.length > 0 && (
          <UsersTable users={users} businessId={+businessId} />
        )}
        {users && users.length === 0 && (
          <IconInfoCard
            title="Aún no hay usuarios agregados"
            info="Aquí aparecerán los usuarios de este organización"
            icon={HiOutlineRocketLaunch}
            iconColor="text-indigo-600"
          />
        )}
      </div>

      <div className="card border border-gray-200 mt-10">
        <div className="flex py-2 justify-between">
          <h1 className="text-lg">Invitaciones</h1>
          <div className="w-12">
          <Button onClick={handleInviteUser} variant="secondary">
            {' '}
            +{' '}
          </Button>
          </div>
        </div>
        {isFetchingInvitations && <OrdersTableSkeleton />}{' '}
        {pendingInvitations && pendingInvitations.length > 0 && (
          <InvitationsTable invitations={pendingInvitations} />
        )}
        {pendingInvitations && pendingInvitations.length === 0 && (
          <IconInfoCard
            title="No hay invitaciones pendientes"
            info="Por el momento no hay invitaciones pendientes"
            icon={HiOutlineRocketLaunch}
            iconColor="text-indigo-600"
          />
        )}
      </div>
      <CustomModal
        onRequestClose={() => setShowModal(false)}
        isOpen={showModal}
        actionBar={<div></div>}
      >
        <Fragment>
          <div className="flex flex-col justify-center items-center mb-5">
            <h1 className="text-xl font-medium mb-4">Invitar usuario</h1>
            <p className="text-gray-400 text-sm text-center">
              Podrás invitar usuarios a tu organización
            </p>
          </div>
          <InviteUser onSuccess={handleSucessInviteUser} businessId={+businessId} />
        </Fragment>
      </CustomModal>
    </div>
  );
};

export default AdminUsersPage;
