// LottieAnimation.tsx
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';

interface LottieAnimationProps {
  path: string;
  width?: string; // Accepting string for percentage or pixel values
  height?: string; // Accepting string for percentage or pixel values
  loop?: boolean;
  autoplay?: boolean;
  className?: string; // Accepting className for Tailwind classes
  slice?: boolean;
}

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  path,
  width = '100%',
  height = '100%',
  loop = true,
  autoplay = true,
  className = '',
  slice = false,
}) => {
  const [animationData, setAnimationData] = useState<unknown>(null);

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch(path);
        const data = await response.json();
        setAnimationData(data);
      } catch (error) {
        console.error('Error fetching Lottie animation data:', error);
      }
    };

    fetchAnimationData();
  }, [path]);

  if (!animationData) {
    return <div></div>; // or any loading spinner/component
  }

  const defaultOptions = {
    loop,
    autoplay,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };

  if (slice) {
    defaultOptions.rendererSettings.preserveAspectRatio = 'xMidYMid slice';
  }

  return (
    <div
      className={`flex justify-center items-center ${className}`}
      style={{ width, height }}
    >
      <Lottie
        options={defaultOptions}
        width="100%"
        height="100%"
        isClickToPauseDisabled={true}
      />
    </div>
  );
};

export default LottieAnimation;
