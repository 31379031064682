import { useNavigate } from 'react-router-dom';
import Button from '../components/atoms/Button';
import Image from '../components/atoms/Image';

const TimeoutPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-6 my-6">
      <div className="flex flex-col md:flex-row gap-6 bg-white rounded-lg shadow-sm p-4">
        <div className="flex items-center justify-center p-4">
          <Image src="/timeout-icon.svg" alt="timeout" className="w-auto h-auto max-w-[140px] object-contain" />
        </div>
        <div className="flex flex-col gap-4 p-2 justify-center flex-start text-center md:text-left">
          <h1 className="text-lg font-medium">
            ¡Se acabó el tiempo para comprar!
          </h1>
          <div>
            <p className="text-sm text-gray-500">
              Las entradas seleccionadas se pusieron nuevamente en venta. Si todavía
              estas interesado en comprar, por favor inciá el proceso nuevamente.
            </p>
          </div>
        </div>
      </div>
      <div className="flex md:justify-start justify-center mt-2">
        <Button onClick={() => navigate('../..')}>
          Volver a la página del evento
        </Button>
      </div>
    </div>
  );
};

export default TimeoutPage;
