import React, { ChangeEvent, useState } from 'react';
import Input from './atoms/Input';
import Button from './atoms/Button';
import TextTitleCard from './atoms/TitleTextCard';
import TicketDTO from '../models/ticket';

interface OpenPriceCardProps {
  onAmountChange: (amount: number | undefined) => void;
  ticket: TicketDTO;
}

const OpenPriceCard: React.FC<OpenPriceCardProps> = ({ ticket, onAmountChange }) => {
  const [isAmountSet, setIsAmountSet] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [amount, setAmount] = useState('');

  if (!ticket.has_open_price || !ticket.min_open_price) {
    throw new Error('ticket does not have open price');
  }

  const validateAmount = () => {
    const numericAmount = parseFloat(amount);
    if (Number.isNaN(numericAmount)) {
      setErrMsg('Valor ingresado no es correcto');
      return false;
    }
    if (ticket.min_open_price && numericAmount < ticket.min_open_price) {
      setErrMsg(`El monto debe ser superior a ${ticket.min_open_price}`);
      return false;
    }
    return true;
  };

  const handleApplyAmount = () => {
    setErrMsg('');
    if (isAmountSet) {
      setIsAmountSet(false);
      setAmount('');
      onAmountChange(undefined);
    } else if (validateAmount()) {
      setIsAmountSet(true);
      onAmountChange(parseFloat(amount));
    }
  };

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<unknown>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <TextTitleCard title="Monto a abonar">
      <div>
        <div className="flex justify-center items-center">
          <Input
            id="code"
            label="Monto"
            value={amount}
            labelAbove={false}
            type='number'
            inputMode="numeric"
            onKeyDown={handleKeyDown}
            onChange={handleAmountChange}
            errMsg={errMsg}
            disabled={isAmountSet}
          />
          <Button
            type="button"
            className="ml-5"
            variant="primary"
            onClick={handleApplyAmount}
            onKeyDown={handleKeyDown}
          >
            {isAmountSet ? 'Cancelar' : 'Aplicar'}
          </Button>
        </div>
      </div>
    </TextTitleCard>
  );
};

export default OpenPriceCard;
