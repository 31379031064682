export enum TicketPublicSaleStatus {
  Available = 'available',
  SoldOut = 'sold_out',
  ComingSoon = 'coming_soon',
}
export default interface TicketDTO {
  id: number,
  event_id: number,
  name: string,
  description: string,
  price: number,
  total_amount: number,
  amount_taken: number,
  active: boolean,
  items_create: number,
  max_per_order: number,
  previous_ticket_id: number;
  has_open_price: boolean;
  min_open_price: number | null;
  created_at: string;
  updated_at: string;
  is_extra: boolean;
  price_includes_service_charge: boolean;
  event_sector?: EventSector;
  is_only_admin: boolean;
  sales_starts_at: string | null;
  sales_ends_at: string | null;
  scanning_starts_at: string | null;
  scanning_ends_at: string | null;
  expired_message: string | null;
  public_sale_available: boolean;
  public_sale_status: TicketPublicSaleStatus;
}

export interface CreateTicketDTO {
  name: string,
  price: number,
  total_amount: number,
  active: boolean,
  items_create: number,
  max_per_order: number,
  previous_ticket_id?: number | null;
  has_open_price: boolean;
  min_open_price: number | null | undefined;
  is_extra: boolean;
  description?: string;
  is_only_admin: boolean;
  sales_starts_at?: string | null;
  sales_ends_at?: string | null;
  scanning_starts_at?: string | null;
  scanning_ends_at?: string | null;
  expired_message?: string | null;
}

export interface UpdateTicketDTO extends Partial<CreateTicketDTO> {
  id: number;
}

export interface TicketReservation {
  ticket_id: number;
  amount: number;
}

export interface ReserveTicketsDTO {
  reservations: TicketReservation[];
}

export interface EventSector {
  color: string;
  created_at: string;
  deleted_at: string;
  event_id: number;
  id: number;
  name: string;
  updated_at: string;
}
