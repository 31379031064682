import React from 'react';

const EventPageSkeleton: React.FC = () => (
    <div className="grid grid-cols-1 w-full mt-6">
      <div className="grid grid-cols-1 gap-8 md:gap-12 p-6 bg-white rounded-lg shadow-sm">
        {/* Banner Image Skeleton */}
        <div className="flex items-center justify-center mb-3">
          <div className="w-full h-[200px] md:h-[400px] bg-gray-200 animate-pulse rounded-lg" />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Title and Organizer Skeleton */}
          <div className="flex flex-col gap-4">
            <div className="h-12 bg-gray-200 animate-pulse rounded-lg" />
            <div className="h-6 w-3/4 bg-gray-200 animate-pulse rounded-lg" />
          </div>

          {/* Ticket Skeleton */}
          <div className="h-32 bg-gray-200 animate-pulse rounded-lg" />

          {/* Event Description Skeleton */}
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <div className="h-6 w-48 bg-gray-200 animate-pulse rounded-lg" />
              <div className="space-y-2">
                <div className="h-4 bg-gray-200 animate-pulse rounded-lg" />
                <div className="h-4 bg-gray-200 animate-pulse rounded-lg" />
                <div className="h-4 w-3/4 bg-gray-200 animate-pulse rounded-lg" />
              </div>
            </div>
          </div>

          {/* Event Details Skeleton */}
          <div className="flex flex-col gap-6">
            <div className="w-full">
              <div className="flex flex-col gap-6">
                {/* Age Restriction */}
                <div className="flex flex-col gap-2">
                  <div className="h-6 w-32 bg-gray-200 animate-pulse rounded-lg" />
                  <div className="h-4 w-48 bg-gray-200 animate-pulse rounded-lg" />
                </div>

                {/* Date and Time */}
                <div className="flex flex-col gap-2">
                  <div className="h-6 w-40 bg-gray-200 animate-pulse rounded-lg" />
                  <div className="h-4 w-56 bg-gray-200 animate-pulse rounded-lg" />
                </div>

                {/* Location */}
                <div className="flex flex-col gap-2">
                  <div className="h-6 w-36 bg-gray-200 animate-pulse rounded-lg" />
                  <div className="h-4 w-64 bg-gray-200 animate-pulse rounded-lg" />
                  <div className="h-4 w-48 bg-gray-200 animate-pulse rounded-lg" />
                </div>

                {/* Map Skeleton */}
                <div className="w-full h-64 sm:h-72 md:h-96 bg-gray-200 animate-pulse rounded-lg" />
              </div>
            </div>
          </div>
        </div>

        {/* Payment Methods Animation Skeleton */}
        <div className="w-full h-10 bg-gray-200 animate-pulse rounded-lg" />
      </div>
    </div>
);

export default EventPageSkeleton;
