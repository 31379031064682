import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import Button from './atoms/Button';

interface Props {
  id: number;
  title: string;
  info: string;
  buttonLabel: string;
  redirectPath: string;
  onButtonClick?: (id: number) => void;
  extraButton?: React.ReactNode;
  image: string;
}

const EventMenuOption: React.FC<Props> = ({
  id,
  title,
  info,
  buttonLabel,
  redirectPath,
  onButtonClick,
  extraButton,
  image,
}) => {
  const navigate = useNavigateWithQuery();

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick(id);
    }
    navigate(redirectPath);
  };

  return (
    <div className="flex h-[152px] p-2 flex-col items-center gap-3 bg-white rounded-[16px] shadow-sm">
      <div className="flex items-end gap-4 self-stretch">
        <img
          src={image}
          alt="event picture"
          width={80}
          height={80}
          className="rounded-lg"
        />
        <div className="flex flex-col self-start pt-1">
          <h1 className="text-xl font-semibold text-gray-700">{title}</h1>
          <p className="text-sm text-gray-600 line-clamp-2 max-w-[300px]">{info}</p>
        </div>
      </div>
      <div className="flex justify-center items-center gap-2 self-stretch">
          <Button onClick={handleButtonClick} variant="secondary">
            {buttonLabel}
          </Button>
        {extraButton}
      </div>
    </div>
  );
};

export default EventMenuOption;
