import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import toast from 'react-hot-toast';
import { useState } from 'react';
import SortableTable from './atoms/SortableTable';
import { UserWithBusinessAndEvent } from '../models/user';
import MenuDropdown from './atoms/MenuDropdown';
import { useDeleteUserEventMutation, useGetAllReferralsQuery, useLazyGetSellerEventQrQuery } from '../store';
import CustomModal from './atoms/CustomModal';
import LinkReferralToSeller from './LinkReferralToSeller';
import useGetUserRole from '../hooks/useGetUserRole';

interface Props {
  users: UserWithBusinessAndEvent[];
  businessId: number;
  eventId: number;
}

const SellersTable: React.FC<Props> = ({ users, businessId, eventId }) => {
  const [deleteUserEvent, resultsDelete] = useDeleteUserEventMutation();
  const [getSellerEventQr] = useLazyGetSellerEventQrQuery();
  const { data: referrals } = useGetAllReferralsQuery({ businessId, eventId });
  const [showModal, setShowModal] = useState(false);
  const [currentUser, setCurrentUser] = useState<UserWithBusinessAndEvent | null>(null);
  const role = useGetUserRole();

  const handleDeleteUserEvent = async (user: UserWithBusinessAndEvent) => {
    toast.promise(
      deleteUserEvent({
        businessId,
        eventId: +eventId,
        userEventId: user.user_event.id,
      }).unwrap(),
      {
        loading: 'Eliminando...',
        success: <b>Vendedor eliminado con éxito</b>,
        error: <b>Error al eliminar vendedor.</b>,
      },
    );
  };

  const handleDownloadQR = async (user: UserWithBusinessAndEvent) => {
    const res = await toast.promise(
      getSellerEventQr({
        businessId,
        eventId: +eventId,
        userEventId: user.user_event.id,
      }).unwrap(),
      {
        loading: 'Descargando QR...',
        success: <b>QR descargado con éxito</b>,
        error: <b>Error al descargar QR.</b>,
      },
    );

    if (res) {
      const a = document.createElement('a');
      a.href = `data:image/png;base64,${res.qr_image_b64}`;
      a.download = `qr_rrpp_${user.username}.png`;
      a.click();
    }
  };

  const handleLinkReferral = (user: UserWithBusinessAndEvent) => {
    setShowModal(true);
    console.log(user);
    setCurrentUser(user);
  };

  const dropDownOptions = [
    {
      label: 'Eliminar',
      onClick: handleDeleteUserEvent,
    },
    {
      label: 'Descargar QR RR.PP',
      onClick: handleDownloadQR,
    },
    {
      label: 'Asignar Referido',
      onClick: handleLinkReferral,
      hidden: role !== 'ROOT',
    },
  ];

  const config = [
    {
      label: 'Usuario',
      render: (user: UserWithBusinessAndEvent) => user.username,
    },
    {
      label: 'Comisión',
      render: (user: UserWithBusinessAndEvent) => `${user.user_event.seller_comission_rate * 100}%`,
    },
    {
      label: 'Activo',
      render: (user: UserWithBusinessAndEvent) => <div className='flex justify-center items-center'> {user.user_event.active ? <AiFillCheckCircle className=' text-green-600' /> : <AiFillCloseCircle className='text-red-600' />}</div>,
    },
    {
      label: '',
      render: (item: UserWithBusinessAndEvent) => (
        <div>
          <MenuDropdown<UserWithBusinessAndEvent>
            options={dropDownOptions}
            data={item}
            loading={resultsDelete.isLoading}
          />
        </div>
      ),
    },
  ];

  const keyFn = (user: UserWithBusinessAndEvent) => `${user.id}`;

  return (
    <div>
      <SortableTable data={users} config={config} keyFn={keyFn} pagination={false} search={false}/>
      <CustomModal
        onRequestClose={() => setShowModal(false)}
        isOpen={showModal}
      >
        {currentUser && <LinkReferralToSeller
          user={currentUser}
          availableReferrals={referrals || []}
          onClose={() => setShowModal(false)}
        />}
      </CustomModal>
    </div>
  );
};

export default SellersTable;
