import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Button from './Button';

interface ModalProps {
  children: React.ReactNode;
  actionBar?: React.ReactNode;
  isOpen: boolean;
  hideCancelButton?: boolean,
  onAfterOpen?: () => void;
  onRequestClose: () => void;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '70vh',
    overflow: 'auto',
  },
};
Modal.setAppElement('#root');

const CustomModal: React.FC<ModalProps> = ({
  children,
  actionBar,
  isOpen,
  hideCancelButton,
  onAfterOpen,
  onRequestClose,
}) => {
  const [actionBarContent, setActionBarContent] = useState(actionBar);

  useEffect(() => {
    setActionBarContent(actionBar);
  }, [actionBar]);

  const onClose = () => {
    setActionBarContent(null);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {children}
      <div className="flex flex-col justify-center items-center">
        {actionBarContent}
        {!hideCancelButton && <Button variant="secondary" onClick={onClose} className='text-center w-full mx-5 mt-2'>
          Cancelar
        </Button>}
      </div>
    </Modal>
  );
};

export default CustomModal;
