import classNames from 'classnames';
import { FC, InputHTMLAttributes, forwardRef } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Tooltip from './Tooltip';

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  errMsg?: string;
  tooltipText?: string;
  labelAbove?: boolean;
  textWhite?: boolean;
  additionalClasses?: string;
  isSearch?: boolean;
}

const Input: FC<CustomInputProps> = forwardRef<
HTMLInputElement,
CustomInputProps
>(({
  id, label, errMsg, type, tooltipText, labelAbove, textWhite, additionalClasses, isSearch = false, ...rest
}, ref) => {
  const commonClasses = 'text-black p-3 border focus:outline-none';
  const focusClasses = 'focus:rounded-[0.1875rem] focus:border-gray-400';
  const textInputClasses = classNames(
    commonClasses,
    focusClasses,
    'w-full',
    {
      'border-red-500': errMsg,
      'text-white': textWhite,
      'bg-transparent': !rest.disabled,
      'bg-gray-100': rest.disabled,
    },
    'border-1',
    'border-gray-300',
    'rounded',
    additionalClasses,
  );

  const dateInputClasses = classNames(
    commonClasses,
    focusClasses,
    'w-full',
    'text-left',
    'appearance-none',
    {
      'border-red-500': errMsg,
    },
  );

  return (
    <div className="form-control flex flex-col w-full relative">
      {labelAbove && (
        <div className="flex items-center w-full">
          <label htmlFor={id} className="text-left">
            {label}
          </label>
          {tooltipText && (
            <Tooltip content={tooltipText}>
              <AiOutlineQuestionCircle className="ml-1" />
            </Tooltip>
          )}
        </div>
      )}
      <div className="relative">
        {isSearch && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5 pointer-events-none"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l5.272 5.272a1 1 0 01-1.414 1.414l-5.272-5.272A6 6 0 012 8z" />
          </svg>
        )}
        <input
          ref={ref}
          type={type}
          id={id}
          placeholder={label}
          className={classNames(
            type === 'date' ? dateInputClasses : textInputClasses,
            isSearch ? 'pl-10' : '',
          )}
          {...rest}
          onWheel={(e) => e.currentTarget.blur()}
        />
      </div>
      <span className="text-red-500 white-text-shadow">{errMsg}</span>
    </div>
  );
});

export default Input;
