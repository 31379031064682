import React from 'react';

const AvailableEventsPageSkeleton: React.FC = () => (
    <div className="flex flex-col gap-8 py-10 bg-gray-50 h-full">
      {/* Carousel skeleton */}
      <div className="p-1">
        <div className="w-full h-[400px] bg-gray-200 animate-pulse rounded-lg" />
      </div>

      {/* Lottie animation placeholder */}
      <div>
        <div className="w-full h-10 bg-gray-200 animate-pulse rounded" />
      </div>

      {/* "Próximos eventos" header skeleton */}
      <div className="flex flex-row items-center gap-4">
        <div className="h-8 w-40 bg-gray-200 animate-pulse rounded" />
        <div className="h-6 w-6 bg-gray-200 animate-pulse rounded-full" />
        <div className="h-6 w-6 bg-gray-200 animate-pulse rounded" />
      </div>

      {/* Event cards grid skeleton */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-20 gap-6 items-center">
        {Array(6).fill(null).map((_, index) => (
          <div key={index} className="w-full">
            <div className="w-full h-[400px] bg-gray-200 animate-pulse rounded-lg" />
          </div>
        ))}
      </div>
    </div>
);

export default AvailableEventsPageSkeleton;
