import { Outlet } from 'react-router-dom';
import RequireAuth from '../store/utils/RequireAuth';
import config from '../config/config';

const AdminOutlet = () => (
  <RequireAuth>
    <div className="px-3 min-h-screen flex flex-col">
      <div className="flex-grow">
        <Outlet />
      </div>
      <div className="mt-auto pt-4 pb-2">
        <span className="text-xs">v{config.version}</span>
      </div>
    </div>
  </RequireAuth>
);

export default AdminOutlet;
