import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  useGetOrderByCustomIdQuery,
  useSendOrderEmailMutation,
} from '../store';
import CreateOrderSuccessSkeleton from '../components/CreateOrderSuccessSkeleton';
import TitleTextCard from '../components/atoms/TitleTextCard';
import { formatCurrency } from '../utils/shared';
import Button from '../components/atoms/Button';
import Accordion from '../components/atoms/Accordion';
import Carrousel from '../components/atoms/Carrousel';
import Image from '../components/atoms/Image';

const CreateOrderSuccess: React.FC = () => {
  const [searchParams] = useSearchParams();
  const customId = searchParams.get('id');
  const { data: order, isLoading } = useGetOrderByCustomIdQuery(
    { customId: customId || '' },
    { skip: !customId },
  );

  const [sendEmail] = useSendOrderEmailMutation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else {
      setIsButtonDisabled(false);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const handleResendEmail = async () => {
    if (!order || isButtonDisabled) {
      return;
    }

    try {
      setIsButtonDisabled(true);
      setCountdown(60);
      await sendEmail({
        businessId: order.event.business_id,
        eventId: order.event.id,
        customId: order.custom_id,
      });
    } catch (error) {
      console.error('Error sending email:', error);
      setIsButtonDisabled(false);
      setCountdown(0);
    }
  };

  if (isLoading) {
    return <CreateOrderSuccessSkeleton />;
  }

  if (!order) {
    console.error('no order');
    return <div>Orden no encontrada</div>;
  }

  const unpaid = order.is_manual || order.total_amount === 0;

  let ticketCount = 0;
  let extraCount = 0;

  return (
    <div className="flex flex-col gap-6 my-6">
      <div className="flex items-center justify-center w-full">
        <Carrousel
          images={[{ url: order.event.banner_image || '', eventId: order.event.id || 0 }]}
        />
      </div>
      <div className="flex flex-col md:flex-row gap-6 bg-white rounded-lg shadow-sm p-4">
        <div className="flex items-center justify-center p-4">
          <Image src="/check-success.svg" alt="success" height={147} width={161} />
        </div>
        <div className="flex flex-col gap-4 p-2 justify-center flex-start text-center md:text-left">
          <h1>
            ¡{unpaid ? 'Solicitud' : 'Compra'} de entradas exitosa!
          </h1>
          <div>
            <p>
              Número de orden: <strong> #{order.display_id} </strong>
            </p>
          </div>
          <div>
            <p>
              Tu {unpaid ? 'solicitud' : 'compra'} de tickets para "
              {order.event.name}" fue procesada con éxito.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-6 w-full">
          <TitleTextCard>
            {order.items.map((item, index) => {
              if (!item.ticket.is_extra) {
                ticketCount += 1;
              } else {
                extraCount += 1;
              }
              return (
                <div className="flex flex-col" key={index}>
                  <div className="my-2 text-secondary text-gray-500">
                    <div>
                      <span className="font-medium">
                        {!item.ticket.is_extra && <span>Entrada {ticketCount} - {item.ticket.name} {item.event_seat ? `/ ${item.event_seat.name}` : ''}</span>}
                        {item.ticket.is_extra && <span> Extra {extraCount} - {item.ticket.name}</span>}
                      </span>{' '}
                      <span
                        className={`${order.coupon_id ? 'line-through text-xs' : ''}`}
                      >
                        {formatCurrency(
                          item.ticket.has_open_price
                            ? order.total_amount
                            : item.ticket.price,
                        )}
                      </span>
                      {order.coupon_id && (
                        <span className="text-sm text-gray-800">
                          {' '}
                          {formatCurrency(
                            item.ticket.price * (1 - order.coupon.value),
                          )}
                        </span>
                      )}
                    </div>
                    {order.event.require_id_tickets && (
                      <div>
                        {item.name} {item.last_name} - {item.person_id}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </TitleTextCard>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="bg-yellow py-3 px-3 text-center rounded-lg w-full">
          {!order.is_manual && order.total_amount > 0 && (
            <p>
              Estamos procesando tu pago. Una vez que sea aprobado te llegará un
              correo con tus entradas.
            </p>
          )}
          {order.total_amount === 0 && (
            <p>
              Se están procesando los tickets correspondientes. En breve serán
              enviados a tu correo electrónico.
            </p>
          )}
          {order.is_manual && order.total_amount > 0 && (
            <p>
              Tu solicitud de entradas fue procesada con éxito. Una vez que la
              operadora confirme tu pago te llegará un correo con tus entradas.
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-6">
        {order.status === 'paid' && (
          <div className="text-gray-600 text-base items-center justify-center w-full">
            <Accordion title="¿No recibiste tus entradas?">
              <div>
                <p className="font-thin">
                  Es posible que no tengas espacio para recibir nuevos correos.
                  Liberá espacio y envialo nuevamente.
                </p>
                <div className="flex justify-center mt-5 text-center mb-2">
                  <Button
                    onClick={handleResendEmail}
                    disabled={isButtonDisabled}
                    variant="primary"
                  >
                    {isButtonDisabled
                      ? `Reenviar correo (${countdown}s)`
                      : 'Reenviar correo'}
                  </Button>
                </div>
              </div>
            </Accordion>
          </div>
        )}
        <p className="px-3 text-sm text-center">
          Si tenés alguna duda podés comunicarte a nuestro{' '}
          <a
            className="underline"
            href="https://wa.me/542645515045"
            target="_blank"
          >
            WhatsApp
          </a>
          .
        </p>
      </div>
    </div >
  );
};

export default CreateOrderSuccess;
