import { useEffect, useState } from 'react';
import { BsCheck2Circle } from 'react-icons/bs';
import { PiWarningCircleLight } from 'react-icons/pi';
import Switch from './atoms/Switch';
import SortableTable from './atoms/SortableTable';
import OrderItem from '../types/OrderItem';
import { useDeleteOrderMutation, useUpdateOrderStatusMutation } from '../store';
import IconInfoCard from './atoms/IconInfoCard';
import Button from './atoms/Button';
import CustomModal from './atoms/CustomModal';
import { formatCurrency } from '../utils/shared';
import { OrderDTO } from '../models/order';

interface Props {
  orders: OrderDTO[];
  eventId: number;
  businessId: number;
}

const PendingOrdersTable: React.FC<Props> = ({ orders, eventId, businessId }) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [updateOrderStatus, resultsUpdate] = useUpdateOrderStatusMutation();
  const [deleteOrder, resultsDelete] = useDeleteOrderMutation();
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);
  const [actionBar, setActionBar] = useState(<div></div>);
  const [sortedOrderItems, setSorterOrderItems] = useState<OrderItem[]>([]);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);

  useEffect(() => {
    setOrderItems(
      orders.filter((o) => o.items && o.items.length > 0)
        .map((o) => ({
          name: `${o.items[0].name} ${o.items[0].last_name}`,
          person_id: o.items[0].person_id,
          id: o.id,
          amount: o.total_amount,
          order_status: o.status,
          item_id: o.items[0].id,
          first_name: o.items[0].name,
          last_name: o.items[0].last_name,
          email: o.email,
          order_id: o.id,
          is_manual: o.is_manual,
          created_at: o.created_at,
          order_display_id: o.display_id,
          ticket_name: o.items[0].name,
          ticket_is_extra: false,
          order_custom_id: o.custom_id,
        })),
    );
  }, [orders]);

  useEffect(() => {
    setSorterOrderItems([...orderItems].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));
  }, [orderItems]);

  const handleSelectItem = (selected: boolean, id: number) => {
    if (selected) {
      return setSelectedItems([...selectedItems, id]);
    }
    return setSelectedItems(selectedItems.filter((idItem) => idItem !== id));
  };

  const handleConfirmRejectOrders = () => {
    selectedItems.map((id) => {
      deleteOrder({
        businessId: +businessId,
        eventId: +eventId,
        orderId: id,
      });
      return setSelectedItems(selectedItems.filter((p) => p !== id));
    });
    setShowModal(false);
  };

  const handleRejectOrders = () => {
    setModalBody(
      <IconInfoCard
        iconColor="text-red-600"
        icon={PiWarningCircleLight}
        title="¿Deseas rechazar la solicitud?"
        info="Una vez rechazada desaparecerá de la lista de solicitudes."
      />,
    );
    setActionBar(
      <div className="w-full">
        <Button
          variant="danger"
          onClick={handleConfirmRejectOrders}
          className="text-center w-full mt-2"
        >
          Rechazar
        </Button>
      </div>,
    );
    setShowModal(true);
  };

  const handleConfirmAcceptOrders = () => {
    console.log({ selectedItems });
    selectedItems.map(async (id) => {
      await updateOrderStatus({
        businessId,
        eventId: +eventId,
        orderId: id,
        newStatus: 'paid',
      });
      return setSelectedItems(selectedItems.filter((p) => p !== id));
    });
    setShowModal(false);
  };

  const handleAcceptOrders = () => {
    setModalBody(
      <IconInfoCard
        iconColor="text-green-600"
        icon={BsCheck2Circle}
        title="¿Deseas aceptar la solicitud?"
        info="Una vez que la aceptes pasará a formar parte de la lista automáticamente."
      />,
    );
    setActionBar(
      <div className="w-full">
        <Button
          variant="primary"
          onClick={handleConfirmAcceptOrders}
          className="text-center w-full mt-2"
        >
          Aceptar
        </Button>
      </div>,
    );
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const config = [
    {
      label: '',
      render: (item: OrderItem) => (
        <div>
          <Switch
            label=""
            labelClassName="text-gray-900"
            onChange={(e) => handleSelectItem(e.target.checked, item.id)}
            checked={false}
          />
        </div>
      ),
    },
    {
      label: 'ID',
      render: (item: OrderItem) => item.order_display_id,
    },
    {
      label: 'Apellido y nombre',
      render: (item: OrderItem) => item.name,
      sortValue: (item: OrderItem) => item.name,
    },
    {
      label: 'Monto',
      render: (item: OrderItem) => (
        <label className="border-b rounded border-gray-200 bg-yellow-100 text-amber-700 p-0.5 text-center">
          {formatCurrency(item.amount)}
        </label>
      ),
    },
  ];

  const keyFn = (item: OrderItem) => item.id;

  return (
    <div>
      <SortableTable data={sortedOrderItems} config={config} keyFn={keyFn} pagination={true} search={true} />
      <div className="flex justify-center align-items-center">
        <Button
          loading={resultsUpdate.isLoading}
          disabled={selectedItems.length === 0}
          variant="primary"
          onClick={handleAcceptOrders}
          className="mr-4"
        >
          Aceptar
        </Button>
        <Button
          loading={resultsDelete.isLoading}
          disabled={selectedItems.length === 0}
          variant="secondary"
          onClick={handleRejectOrders}
        >
          Rechazar
        </Button>
      </div>
      <CustomModal
        actionBar={actionBar}
        onRequestClose={handleClose}
        isOpen={showModal}
      >
        {modalBody}
      </CustomModal>
    </div>
  );
};

export default PendingOrdersTable;
