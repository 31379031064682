import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../store';
import config from '../config/config';
import Button from '../components/atoms/Button';
import TextTitleCard from '../components/atoms/TitleTextCard';

const PaymentsPage: React.FC = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { token } = useSelector(
    (state: RootState) => state.auth,
  );
  const { event_id: eventId, business_id: businessId } = useParams();

  const handleDownloadExport = () => {
    setIsDownloading(true);
    axios
      .get(
        `${config.baseURL}/v1/businesses/${businessId}/events/${eventId}/payments?xlsx=true`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `payments_report_${new Date().toISOString()}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => console.error(e))
      .finally(() => setIsDownloading(false));
  };
  return (
    <div className='flex align-items-center justify-center mt-5'>
      <TextTitleCard title='Descargar reporte'>
        <span className='mb-5'>Actualmente estamos trabajando en mejorar esta página, por ahora puedes descargar un reporte de excel.</span>
        <Button onClick={handleDownloadExport} loading={isDownloading}>
          Descargar reporte pagos
        </Button>
      </TextTitleCard>
    </div>
  );
};

export default PaymentsPage;
