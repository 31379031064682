import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetEventByIdQuery } from '../store';
import { parseFriendlyDate } from '../utils/dates';
import EventPageSkeleton from '../components/EventPageSkeleton';
import { setSelectedEvent } from '../store/slices/events';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import Carrousel from '../components/atoms/Carrousel';
import LottieAnimation from '../components/atoms/LottieAnimation';
import Ticket from '../components/Ticket';

interface Props {
  loadedEventId?: number;
}

const EventPage: React.FC<Props> = ({ loadedEventId }) => {
  const { event_id: eventId } = useParams();
  const eventIdToUse = loadedEventId || +(eventId || 0);
  const { data: event, isLoading: eventLoading } = useGetEventByIdQuery(
    { eventId: eventIdToUse },
    { skip: !eventIdToUse },
  );
  const business = event?.business;
  const dispatch = useDispatch();
  const navigate = useNavigateWithQuery();

  const [clickedGetTickets, setClickedGetTickets] = useState(false);

  useEffect(() => {
    if (event) {
      dispatch(setSelectedEvent(event));
    }
  }, [business, event]);

  const tickets = event?.tickets?.filter((t) => t.active && !t.is_only_admin);
  console.log({ tickets });

  if (eventLoading) {
    return <EventPageSkeleton />;
  }

  if (!business || !event || !tickets) {
    navigate('..');
    console.error('missing required data', { business, event, tickets });
    return <div></div>;
  }

  const importantInfo = event.terms
    ? event.terms.split('\n').map((str, index, array) => (
      <div key={index} className="mb-1">
        {str}
        {index !== array.length - 1 && <br />}
      </div>
    ))
    : null;

  const eventDescription = event.description
    .split('\n')
    .map((str, index, array) => (
      <div key={index} className="mb-1">
        {str}
        {index !== array.length - 1 && <br />}
      </div>
    ));

  if (!event || !business) {
    throw new Error('no event, business');
  }

  const handleClickBuyTickets = () => {
    setClickedGetTickets(true);
    navigate(`orders/new/${event.has_numerated_seats ? 'seats' : 'tickets'}`);
  };

  return (
    <div className="grid grid-cols-1 w-full mt-6">
      <div className="grid grid-cols-1 gap-8 md:gap-12 p-6 bg-white rounded-lg shadow-sm">
        <div className="flex items-center justify-center mb-3">
          <Carrousel
            images={[{ url: event.banner_image, eventId: event.id }]}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col">
            <h1 className="text-[24px] md:text-[48px] font-semibold">{event.name}</h1>
            <h2 className="text-lg md:text-[20px]">Organiza: {business.name}</h2>
          </div>
          <div>
            <Ticket price={Math.min(...tickets.map((ticket) => ticket.price))} onClick={handleClickBuyTickets} />
          </div>
          <div className='flex flex-col gap-6'>
            <div className="flex flex-col gap-2">
              <h1 className="text-lg md:text-[20px] font-semibold">Sobre el evento</h1>
              <p>{eventDescription}</p>
            </div>
            {importantInfo && (
              <div className="flex flex-col gap-2">
                <h1 className="text-lg md:text-[20px] font-semibold">Más información</h1>
                <p>{importantInfo}</p>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-6">
            <div className="w-full">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-2">
                  <h1 className="text-lg md:text-[20px] font-semibold">Público</h1>
                  <p>
                    {event.min_age
                      ? `Mayores de ${event.min_age} años`
                      : 'Apto para todo público'}
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="text-lg md:text-[20px] font-semibold">Fecha y hora</h1>
                  <p>
                    {parseFriendlyDate(event.date, true)} - {event.time}hs
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="text-lg md:text-[20px] font-semibold">Ubicación</h1>
                  <p>{event.place}</p>
                  <p>{event.address}</p>
                </div>
                {event.maps_link && (
                  <iframe
                    src={event.maps_link}
                    className="w-full mx-auto h-64 sm:h-72 md:h-96 rounded-lg"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                )}
              </div>
            </div>
          </div>
        </div>
        <LottieAnimation height='30px' path="/cards.json" className="w-full h-10" slice />
      </div>
      <div className="my-10">
        {tickets.length === 0 && (
          <div
            className={`flex justify-center mb-3 ${clickedGetTickets ? 'font-medium' : ''}`}
          >
            En este momento no hay entradas disponibles
          </div>
        )}
      </div>
    </div>
  );
};

export default EventPage;
