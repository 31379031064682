/* eslint-disable no-nested-ternary */
import { Fragment, useEffect, useState } from 'react';
import { BsDownload, BsPlus } from 'react-icons/bs';
import { FiUserCheck, FiUsers } from 'react-icons/fi';
import axios from 'axios';
import { useSelector } from 'react-redux';
import PendingOrdersTable from '../components/PendingOrdersTable';
import {
  RootState,
  useGetEventOrdersQuery,
  useGetOneSellerSummaryQuery,
  useGetSellerOrdersQuery,
} from '../store';
import { OrderDTO } from '../models/order';
import Button from '../components/atoms/Button';
import OrderItem from '../types/OrderItem';
import config from '../config/config';
import IconInfoCard from '../components/atoms/IconInfoCard';
import OrdersTableSkeleton from '../components/OrdersTableSkeleton';
import ApprovedOrdersTable from '../components/ApprovedOrdersTable';
import Switch from '../components/atoms/Switch';
import useGetParams from '../hooks/useGetParams';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import useGetUserRole from '../hooks/useGetUserRole';
import mapOrderToDisplayItems from '../utils/mapOrderToDisplayItems';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';
import SellerSummary from '../components/SellerSummary';
import LoadingLottie from '../components/atoms/LoadingLottie';
import ReferralSummary from '../components/ReferralSummary';

const filterNotActive = (orders: OrderDTO[]): OrderDTO[] => orders.filter((o) => o.status === 'pending' && o.is_manual);

const parseApprovedItems = (orders: OrderDTO[]): OrderItem[] => orders
  .filter((o) => o.status === 'paid' && o.items && o.items.length > 0)
  .flatMap((order) => order.items
    .filter((item) => !item.ticket?.is_extra)
    .map((item) => mapOrderToDisplayItems(order, item)));

const parseExtraItems = (orders: OrderDTO[]): OrderItem[] => orders
  .filter((o) => o.status === 'paid' && o.items && o.items.length > 0)
  .flatMap((order) => order.items
    .filter((item) => item.ticket?.is_extra)
    .map((item) => mapOrderToDisplayItems(order, item)));

const EventOrders: React.FC = () => {
  const navigate = useNavigateWithQuery();
  const { event, eventLoading } = useGetSelectedEvent();
  const { eventId, businessId } = useGetParams();
  const [pendingOrders, setPendingOrders] = useState<OrderDTO[]>([]);
  const [activeOrders, setActiveOrders] = useState<OrderItem[]>([]);
  const [extraOrders, setExtraOrders] = useState<OrderItem[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showPending, setShowPending] = useState(false);
  const role = useGetUserRole();
  const [isFetching, setIsFetching] = useState(false);

  const { token, user: userData } = useSelector(
    (state: RootState) => state.auth,
  );

  const { data: sellerSummary, isFetching: isSellerSummaryLoading } = useGetOneSellerSummaryQuery(
    {
      businessId,
      eventId,
      sellerId: userData.id,
    },
    { skip: role !== 'SELLER' },
  );

  const { data: eventOrders, isFetching: isFetchingEventOrders } = useGetEventOrdersQuery(
    {
      businessId: event?.business_id || 0,
      eventId: +eventId,
    },
    { skip: !event || role === 'SELLER' },
  );

  const { data: sellerOrders, isFetching: isFetchingSellerOrders } = useGetSellerOrdersQuery(
    {
      businessId: event?.business_id || 0,
      eventId: +eventId,
    },
    { skip: !event || role !== 'SELLER' },
  );

  useEffect(() => {
    setIsFetching(isFetchingEventOrders || isFetchingSellerOrders);
  }, [isFetchingEventOrders, isFetchingSellerOrders]);

  useEffect(() => {
    const orders = role === 'SELLER' ? sellerOrders : eventOrders;
    if (orders?.data) {
      setPendingOrders(filterNotActive(orders.data));
      setActiveOrders(parseApprovedItems(orders.data));
      setExtraOrders(parseExtraItems(orders.data));
    }
  }, [eventOrders, sellerOrders]);

  const handleDownloadExport = () => {
    setIsDownloading(true);
    axios
      .get(
        `${config.baseURL}/v1/businesses/${event?.business_id}/events/${eventId}/orders?xlsx=true`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'lista.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => console.error(e))
      .finally(() => setIsDownloading(false));
  };

  const handleAddOrder = () => navigate('new/tickets');

  const handleUala = () => {
    setShowPending((value) => !value);
  };

  return (
    <div className="admin w-full">
      <div>
        <h1 className="text-2xl px-4 pt-4 pb-2 font-medium">
          Evento: {event?.name}
        </h1>
        {(userData.type === 'ROOT'
          || (event?.receives_wire_transfers && role === 'ADMIN')) && (
          <Switch
            label="Mostrar ordenes pendientes"
            checked={showPending}
            onChange={handleUala}
          />
        )}
        {pendingOrders && showPending && (
          <div className="card border border-gray-200">
            <div className="flex justify-between items-center py-2">
              <h1 className="text-lg">Solicitudes pendientes</h1>
              <label className="ml-2 inline-block text-xs px-2 rounded-full bg-gray-200 text-center mt-1.5">
                {pendingOrders.length} pendientes
              </label>
            </div>
            {isFetching || eventLoading ? (
              <OrdersTableSkeleton />
            ) : pendingOrders.length > 0 ? (
              <Fragment>
                <PendingOrdersTable
                  orders={pendingOrders}
                  eventId={+eventId}
                  businessId={+businessId}
                />
              </Fragment>
            ) : (
              <IconInfoCard
                title="Sin solicitudes pendientes"
                info="Aquí te aparecerán las solicitudes pendientes de clientes que necesitan entradas"
                icon={FiUserCheck}
                iconColor="text-indigo-600"
              />
            )}
          </div>
        )}
      </div>
      {activeOrders && (
        <div className="card border border-gray-200">
          <div className="flex justify-between py-2">
            <h1 className="text-lg">Entradas vendidas</h1>
            <label className="ml-2 inline-block text-xs px-2 rounded-full bg-gray-200 text-center h-5 mt-1.5">
              {activeOrders.length} tickets
            </label>
            <div className='w-12'>
            <Button variant='secondary' className="ml-10 text-lg" onClick={handleAddOrder}>
              <BsPlus />
            </Button>
            </div>
          </div>
          {isFetching ? (
            <OrdersTableSkeleton />
          ) : !isFetching && activeOrders?.length > 0 ? (
            <ApprovedOrdersTable orderItems={activeOrders} />
          ) : (
            <IconInfoCard
              title="Aún no hay invitados confirmados"
              info="Aquí comenzarás a ver a los invitados una vez que sean aceptados"
              icon={FiUsers}
              iconColor="text-indigo-600"
            />
          )}
        </div>
      )}
      {extraOrders && extraOrders.length > 0 && (
        <div className="card border border-gray-200 mt-8">
          <div className="flex justify-between py-2">
            <h1 className="text-lg">Extras vendidos</h1>
            <label className="ml-2 inline-block text-xs px-2 rounded-full bg-gray-200 text-center h-5 mt-1.5">
              {extraOrders.length} extras
            </label>
          </div>
          {isFetching ? (
            <OrdersTableSkeleton />
          ) : !isFetching && extraOrders?.length > 0 ? (
            <ApprovedOrdersTable orderItems={extraOrders} />
          ) : (
            <IconInfoCard
              title="Aún no hay extras vendidos"
              info="Aquí comenzarás a ver a los extras vendidos una vez que sean aceptados"
              icon={FiUsers}
              iconColor="text-indigo-600"
            />
          )}
        </div>
      )}
      {role !== 'SELLER' && (
        <div className="flex items-center justify-center mt-8">
          <Button
            className="flex"
            onClick={handleDownloadExport}
            loading={isDownloading}
          >
            <div className="flex items-center justify-center">
              <BsDownload />
              Descargar reporte
            </div>
          </Button>
        </div>
      )}
      {role === 'SELLER' && (
        <div className="my-8">
          {sellerSummary && <SellerSummary summary={sellerSummary} />}
          {isSellerSummaryLoading && <LoadingLottie />}
        </div>
      )}
      {(role === 'SELLER' && sellerSummary?.referral) && (
        <div className="my-8">
          {sellerSummary && <ReferralSummary referral={sellerSummary.referral} />}
          {isSellerSummaryLoading && <LoadingLottie />}
        </div>
      )}
    </div>
  );
};

export default EventOrders;
