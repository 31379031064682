import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';
import './Header.css';

const Header: React.FC = () => (<div>
    <div className='mobile'>
      <MobileHeader /></div>
    <div className='desktop'><DesktopHeader /></div>
  </div>);

export default Header;
