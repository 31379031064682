import { useEffect, useState } from 'react';
import LoadingLottie from '../components/atoms/LoadingLottie';
import TextTitleCard from '../components/atoms/TitleTextCard';
import useGetParams from '../hooks/useGetParams';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';
import { formatCurrency } from '../utils/shared';
import { useGetEventOrdersReportQuery } from '../store';
import TicketsSummaryTable from '../components/SellersTicketSummary';
import { EventSalesReportDTO } from '../models/event';
import SortableTable from '../components/atoms/SortableTable';

interface SalesArray {
  title: string;
  count: number;
  total: number;
}

const salesReportToTableArray = (reportData: EventSalesReportDTO) => {
  const tableArray: SalesArray[] = [
    {
      title: 'Venta online',
      count: reportData.tickets_count_online,
      total: reportData.total_amount_online,
    },
    {
      title: 'Venta manual',
      count: reportData.tickets_count_manual,
      total: reportData.total_amount_manual,
    },
    {
      title: 'Total',
      count: reportData.total_tickets_count,
      total: reportData.total_amount,
    },
  ];
  return tableArray;
};

const EventSalesReportPage: React.FC = () => {
  const [salesArray, setSalesArray] = useState<SalesArray[]>([]);
  const { eventId, businessId } = useGetParams();
  const { event } = useGetSelectedEvent();
  const { data: reportData, isLoading } = useGetEventOrdersReportQuery({
    businessId,
    eventId,
  });

  useEffect(() => {
    if (reportData) {
      setSalesArray(salesReportToTableArray(reportData));
    }
  }, [reportData]);

  if (isLoading) {
    return <LoadingLottie />;
  }
  if (!reportData) {
    return <div>No hay datos</div>;
  }

  const tableConfig = [
    {
      label: 'Canal de venta',
      render: (s: SalesArray) => s.title,
    },
    {
      label: 'Cantidad',
      render: (s: SalesArray) => s.count,
    },
    {
      label: 'Total',
      render: (s: SalesArray) => formatCurrency(s.total),
    },
  ];

  return (
    <div>
      <h1>Reporte de ventas: {event?.name}</h1>
      <div className="mb-3">
        <TextTitleCard
          title="Resumen de ventas"
          subtitle="Monto neto por canal de ventas, después de aplicar descuentos."
        >
          <SortableTable
            data={salesArray}
            config={tableConfig}
            keyFn={(s) => s.title}
            pagination={false}
            search={false}
          />
        </TextTitleCard>
      </div>
      <div className="mb-3 overflow-x-scroll">
        <TextTitleCard
          title="Resumen de ventas por tipo de entrada"
          subtitle="Monto bruto de ventas por tipo de entrada."
          additionalCardClasses="overflow-x-scroll"
        >
          <TicketsSummaryTable summaries={reportData.ticket_summary} />
        </TextTitleCard>
      </div>
    </div>
  );
};

export default EventSalesReportPage;
