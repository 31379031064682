import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { OrderData } from '../apis/orders';
import { OrderItemData, SellerSummaryDTO } from '../../models/order';
import { PollData } from '../../models/poll';
import { ReservationDTO } from '../../models/seat';

interface OrdersState {
  createdOrder: OrderData | null;
  orderItemData: OrderItemData[];
  pollData: PollData[];
  sellerDetails: SellerSummaryDTO | null;
  reservation: ReservationDTO | null;
}

const initialState: OrdersState = {
  createdOrder: null,
  orderItemData: [],
  pollData: [],
  sellerDetails: null,
  reservation: null,
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setCreatedOrder: (state, action: PayloadAction<OrderData>) => ({ ...state, createdOrderOrder: action.payload }),
    setOrderItemData: (state, action: PayloadAction<OrderItemData[]>) => ({ ...state, orderItemData: action.payload }),
    setPollData: (state, action: PayloadAction<PollData[]>) => ({ ...state, pollData: action.payload }),
    setSellerDetails: (state, action: PayloadAction<SellerSummaryDTO>) => ({ ...state, sellerDetails: action.payload }),
    setReservation: (state, action: PayloadAction<ReservationDTO>) => {
      Cookies.set('reservation', JSON.stringify(action.payload));
      return { ...state, reservation: action.payload };
    },
    clearReservation: (state) => {
      Cookies.remove('reservation');
      return { ...state, reservation: null };
    },
    selectReservation: (state) => {
      let { reservation } = state;
      if (!reservation) {
        const reservationString = Cookies.get('reservation');
        reservation = reservationString ? JSON.parse(reservationString) : null;
      }
      if (reservation && new Date(reservation.expires_at) < new Date()) {
        Cookies.remove('reservation');
        return { ...state, reservation: null };
      }
      return { ...state, reservation };
    },
  },
});

export const {
  setCreatedOrder, setOrderItemData, setPollData, setSellerDetails, setReservation, clearReservation, selectReservation,
} = ordersSlice.actions;
export const ordersReducer = ordersSlice.reducer;
