import { toast } from 'react-hot-toast';
import SortableTable from './atoms/SortableTable';
import MenuDropdown from './atoms/MenuDropdown';
import { useDeleteEventTransactionMutation } from '../store';
import { EventTransactionDTO } from '../models/event-transaction';
import { parse2decimals } from '../utils/shared';

interface Props {
  transactions: EventTransactionDTO[];
  businessId: number;
  eventId: number;
}

const TransactionsTable: React.FC<Props> = ({ transactions, businessId, eventId }) => {
  const [deleteTransaction, resultsDelete] = useDeleteEventTransactionMutation();

  const handleDeleteTransaction = async (transaction: EventTransactionDTO) => {
    toast.promise(
      deleteTransaction({
        businessId,
        eventId,
        transactionId: transaction.id,
      }).unwrap(),
      {
        loading: 'Eliminando...',
        success: <b>Transacción eliminada con éxito</b>,
        error: <b>Error al eliminar transacción.</b>,
      },
    );
  };

  const dropDownOptions = [
    {
      label: 'Eliminar',
      onClick: handleDeleteTransaction,
    },
  ];

  const config = [
    {
      label: 'Descripción',
      render: (transaction: EventTransactionDTO) => transaction.description,
    },
    {
      label: 'Monto',
      render: (transaction: EventTransactionDTO) => `$${parse2decimals(transaction.amount)}`,
    },
    {
      label: '',
      render: (transaction: EventTransactionDTO) => (
        <div>
          <MenuDropdown<EventTransactionDTO>
            options={dropDownOptions}
            data={transaction}
            loading={resultsDelete.isLoading}
          />
        </div>
      ),
    },
  ];

  const keyFn = (transaction: EventTransactionDTO) => `${transaction.id}`;

  return (
    <div>
      <SortableTable
        data={transactions}
        config={config}
        keyFn={keyFn}
        pagination={false}
        search={false}
      />
    </div>
  );
};

export default TransactionsTable;
