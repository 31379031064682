import React from 'react';

const AdminEventsSkeleton: React.FC = () => (
    <div className="flex py-8 px-1 flex-col align-start gap-8 self-stretch animate-pulse">
      {/* Header */}
      <div className="flex items-center gap-4 self-stretch">
        <div className="h-9 w-32 bg-gray-200 rounded"></div>
      </div>

      {/* Create Event Card */}
      <div className="flex flex-col md:flex-row p-4 justify-between self-stretch gap-4 items-center text-center bg-white border border-gray-100 rounded-lg shadow-sm">
        <div className="h-6 w-96 bg-gray-200 rounded"></div>
        <div className="h-10 w-40 bg-gray-200 rounded"></div>
      </div>

      {/* Search Controls */}
      <div className="flex flex-col w-full md:flex-row gap-4">
        <div className="w-full bg-white h-12 rounded-lg">
          <div className="h-full bg-gray-200 rounded-lg"></div>
        </div>
        <div className="w-full bg-white h-12 rounded-lg">
          <div className="h-full bg-gray-200 rounded-lg"></div>
        </div>
        <div className="w-full bg-white h-12 rounded-lg">
          <div className="h-full bg-gray-200 rounded-lg"></div>
        </div>
      </div>

      {/* Settings Button */}
      <div className="flex justify-end">
        <div className="h-8 w-8 bg-gray-200 rounded"></div>
      </div>

      {/* Event Cards Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
        {[...Array(6)].map((_, index) => (
          <div key={index} className="w-full bg-white rounded-lg shadow-sm overflow-hidden">
            {/* Card Image */}
            <div className="h-48 bg-gray-200"></div>
            {/* Card Content */}
            <div className="p-4 space-y-3">
              <div className="h-6 w-3/4 bg-gray-200 rounded"></div>
              <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
              <div className="h-4 w-2/3 bg-gray-200 rounded"></div>
              <div className="h-10 w-full bg-gray-200 rounded"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
);

export default AdminEventsSkeleton;
