/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef, useState } from 'react';
import {
  Col, Modal, Row, Space,
} from 'antd';
import Map from 'ol/Map';
import View from 'ol/View';
import { Vector as VectorLayer } from 'ol/layer';
import { ImageStatic, Vector as VectorSource } from 'ol/source';
import Feature, { FeatureLike } from 'ol/Feature';
import Point from 'ol/geom/Point';
import {
  Style,
  Icon,
} from 'ol/style';
import { Select } from 'ol/interaction';
import 'ol/ol.css';
import { Geometry } from 'ol/geom';
import ImageLayer from 'ol/layer/Image';
import { SelectEvent } from 'ol/interaction/Select';
import { useSelector } from 'react-redux';
import { DisplaySeatDTO, EventSeatStatus } from '../models/seat';
import Button from './atoms/Button';
import { Text } from './Text';
import { RootState, useReserveSeatMutation } from '../store';

interface Props {
  seatsInfo: DisplaySeatDTO[] | null | undefined,
  selectedSeats: DisplaySeatDTO[],
  onSeatsUpdate: (selectedSeats: DisplaySeatDTO[]) => void,
}

const RenderStageMap: React.FC<Props> = ({ seatsInfo, selectedSeats, onSeatsUpdate }) => {
  const [updatedArray, setUpdatedArray] = useState<Array<DisplaySeatDTO>>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [selectedSeat, setSelectedSeat] = useState<DisplaySeatDTO | null>(null);
  const mapElement = useRef<HTMLDivElement | null>(null);
  const selectInteractionRef = useRef<Select | null>(null);
  const mapRef = useRef<Map | null>(null);
  const [reserveSeat, { isLoading: isReserveLoading }] = useReserveSeatMutation();
  const reservation = useSelector((state: RootState) => state.orders.reservation);
  const event = useSelector((state: RootState) => state.events.selectedEvent);
  const viewStateRef = useRef<{ center: number[], zoom: number } | null>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const selectedIconUrl = '/check-circle.svg';
    const getSeatIcon = (feature: FeatureLike) => {
      const seatColor = feature.get('sector_color');
      const svg = `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6V0H0V6Z" fill="${seatColor}"/>
      </svg>`;
      const seatId = feature.get('id');
      const isSelected = selectedSeats.find((seat) => seat.id === seatId);
      if (isSelected) {
        return selectedIconUrl;
      }
      if (feature.get('status') !== EventSeatStatus.Available) {
        return '/seat.svg';
      }

      return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
    };

    if (mapElement.current) {
      if (mapRef.current) {
        const currentView = mapRef.current.getView();
        viewStateRef.current = {
          center: currentView.getCenter() || [0, 0],
          zoom: currentView.getZoom() || 2,
        };
      }

      const view = new View({
        projection: 'EPSG:3857',
        center: viewStateRef.current?.center || [0, 0],
        zoom: viewStateRef.current?.zoom || 2,
        rotation: 0,
        enableRotation: false,
        constrainRotation: false,
      });

      const imageUrl = '/stage-map.png';
      const imageExtent = [-87, -75, 178, 200];

      const imageLayer = new ImageLayer({
        source: new ImageStatic({
          url: imageUrl,
          imageExtent,
        }),
      });

      const vectorSource = new VectorSource();
      const seatFeatures: Feature<Geometry>[] = [];

      seatsInfo?.forEach((seat) => {
        const seatFeature = new Feature({
          ...seat,
          geometry: new Point([seat.x_coordinate, seat.y_coordinate]),
          id: seat.id,
        });
        seatFeatures.push(seatFeature);
      });

      vectorSource.addFeatures(seatFeatures);

      const vectorLayer = new VectorLayer({
        source: vectorSource,
        declutter: true,
        style: (feature: FeatureLike, resolution: number) => {
          const iconScale = Math.max(0.4, 0.4 / resolution);
          const iconUrl = getSeatIcon(feature);
          return new Style({
            image: new Icon({
              anchor: [0.5, 1],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              src: iconUrl,
              scale: iconScale,
            }),
          });
        },
      });

      const map = new Map({
        target: mapElement.current,
        layers: [imageLayer, vectorLayer],
        view,
      });

      const seatExtent = vectorSource.getExtent();
      if (!vectorSource.isEmpty() && !viewStateRef.current) {
        map.getView().fit(seatExtent, {
          size: map.getSize(),
          padding: [50, 50, 50, 50],
        });

        const fittedZoom = view.getZoom();
        if (fittedZoom) {
          view.setMinZoom(fittedZoom - 0.5);
        }
      }

      mapRef.current = map;
      const selectInteraction = new Select({
        style: (_: FeatureLike, resolution: number) => new Style({
          image: new Icon({
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: selectedIconUrl,
            scale: Math.max(0.4, 0.4 / resolution),
          }),
        }),
      });

      map.addInteraction(selectInteraction);

      selectInteraction.on('select', (e: SelectEvent) => {
        if (e.selected.length > 0) {
          const feature = e.selected[0];
          const seatInfo = feature.getProperties();

          if (seatInfo.status !== EventSeatStatus.Available) {
            selectInteraction.getFeatures().clear();
            return;
          }

          setSelectedSeat(seatInfo as DisplaySeatDTO);

          const selected = selectedSeats.find((seat) => seat?.id === seatInfo?.id);
          if (selected) {
            setUpdatedArray(selectedSeats.filter((seat) => seat.id !== seatInfo.id));
          } else {
            setUpdatedArray([...selectedSeats, seatInfo as DisplaySeatDTO]);
            setShowConfirmModal(true);
          }
        }
      });

      return () => {
        map.setTarget(undefined);
        selectInteractionRef.current = null;
      };
    }
  }, [seatsInfo, selectedSeats]);

  const handleCancel = () => {
    const map = mapRef.current;
    if (map) {
      const selectInteraction = map
        .getInteractions()
        .getArray()
        .find((interaction) => interaction instanceof Select) as Select;

      selectInteraction?.getFeatures().clear();
    }

    setUpdatedArray((prev) => prev.filter(() => false));
    setShowConfirmModal(false);
  };

  const handleConfirm = async () => {
    if (!reservation || !event || !selectedSeat) {
      console.error('required data to reserve seat not found', { reservation, event, selectedSeat });
      throw new Error('required data to reserve seat not found');
    }
    await reserveSeat({
      reservationCode: reservation.code,
      businessId: event.business_id,
      eventId: event.id,
      eventSeatId: selectedSeat.id,
    });

    setShowConfirmModal(false);
    onSeatsUpdate(updatedArray);
  };

  return <>
    <div ref={mapElement} className='map-container' style={{ height: '500px', backgroundColor: '#FFFFFF' }} />

    <Modal
      open={showConfirmModal}
      onOk={handleConfirm}
      onCancel={handleCancel}
      width={400}
      footer={() => (
        <Row className='mt-7' gutter={12}>
          <Col span={12}>
            <Button className='w-full' disabled={isReserveLoading} key="back" onClick={handleCancel}>
              Cancelar
            </Button>
          </Col>
          <Col span={12}>
            <Button className='w-full' loading={isReserveLoading} key="submit" variant="primary" onClick={handleConfirm}>
              Confirmar
            </Button>
          </Col>
        </Row>
      )}>
      <Space direction='vertical'>
        <Text size='lg' semibold>Seleccionar asiento</Text>
        <Text size='md' className='text-neutral-600'>Seleccionaste el {selectedSeat?.seat_name} correspondiente al sector {selectedSeat?.sector_name}</Text>
      </Space>
    </Modal>
  </>;
};

export default RenderStageMap;
