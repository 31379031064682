import { ReactNode } from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';

const TextComponent = Typography.Text;

export interface TextProps {
  children?: ReactNode
  width?: number
  className?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  color?: string
  bold?: boolean
  semibold?: boolean
  medium?: boolean
  thin?: boolean
  extralight?: boolean
  light?: boolean
  ellipsis?: boolean
}

const mapSizeClass = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-md',
  lg: 'text-lg',
  xl: 'text-xl',
};

export const Text: React.FC<TextProps> = ({
  children,
  size = 'md',
  color = 'text-neutral-700',
  className = '',
  bold,
  semibold,
  medium,
  thin,
  extralight,
  light,
  ellipsis,
  width,
}) => {
  const styles = classNames(
    mapSizeClass[size],
    color,
    className,
    {
      'font-bold': bold,
      'font-semibold': !bold && semibold,
      'font-medium': !bold && medium,
      'font-thin': !bold && !semibold && thin,
      'font-extralight': !bold && !semibold && extralight,
      'font-light': !bold && !semibold && light,
      'font-regular': !bold && !semibold && !thin && !extralight && !light,
    },
  );

  return (
        <TextComponent ellipsis={ellipsis} style={{ width }} className={styles}>{children}</TextComponent>
  );
};
