import {
  Col, Row, Space,
} from 'antd';
import { Text } from './Text';
import { formatCurrency } from '../utils/shared';

interface TicketInfoProps {
  color: string
  title: string
  description: string
  price: number
}

const TicketInfo = ({
  color, title, description, price,
}: TicketInfoProps) => (
  <Row justify='space-between'>
    <Col className='flex gap-2 ml-5'>
      <div className='rounded-full h-5 w-5' style={{ backgroundColor: color }}></div>
      <Space direction='vertical' size={0}>
        <Text size='sm' medium>{title}</Text>
        <Text size='xs' color='text-neutral-600'>{description}</Text>
      </Space>
    </Col>
    <Col span={8} className='text-right mr-5'>
      <Text size='md'>{formatCurrency(price)}</Text>
    </Col>
  </Row>
);

export default TicketInfo;
