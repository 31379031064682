import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from './Text';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';

interface CountdownTimerProps {
  expirationDate: Date;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ expirationDate }) => {
  const [timeLeft, setTimeLeft] = useState(10000);
  const navigate = useNavigate();
  const { event } = useGetSelectedEvent();

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = Math.floor((expirationDate.getTime() - now.getTime()) / 1000);
      return Math.max(0, difference);
    };

    setTimeLeft(calculateTimeLeft());

    const timer = setInterval(() => {
      const remaining = calculateTimeLeft();
      setTimeLeft(remaining);

      if (remaining <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [expirationDate]);

  useEffect(() => {
    if (timeLeft === 0) {
      navigate(`/events/${event?.id}/orders/new/timeout`);
    }
  }, [timeLeft, navigate]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className='text-center py-3'>
      <Text
        size='sm'
        color={timeLeft <= 2 * 60 ? 'text-error-dark' : 'text-gray-700'}
        medium
      >
        Tiempo restante para comprar: {formatTime(timeLeft)}
      </Text>
    </div>
  );
};

export default CountdownTimer;
