import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Input from './atoms/Input';
import Button from './atoms/Button';
import Accordion from './atoms/Accordion';
import { RootState, useCheckCouponValidationMutation } from '../store';
import CouponDTO from '../types/Coupon';

interface GoutCouponProps {
  onCouponChange: (coupon: CouponDTO | null) => void;
  loadedCode: string | null;
}

const GotCoupon: React.FC<GoutCouponProps> = ({ onCouponChange, loadedCode }) => {
  const event = useSelector((state: RootState) => state.events.selectedEvent);
  const orderItemData = useSelector(
    (state: RootState) => state.orders.orderItemData,
  );

  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');

  if (!event) {
    throw new Error('no event');
  }
  const [code, setCode] = useState('');
  const [checkCouponValidation, couponValidationResults] = useCheckCouponValidationMutation();

  const validateCoupon = (codeVal: string) => {
    checkCouponValidation({
      businessId: event.business_id,
      eventId: event.id,
      amount: orderItemData.length,
      couponCode: codeVal,
    });
  };

  useEffect(() => {
    if (loadedCode) {
      setCode(loadedCode);
      validateCoupon(loadedCode);
    }
  }, [loadedCode]);

  const handleApplyDiscountClick = () => {
    if (isCouponApplied) {
      setIsCouponApplied(false);
      setSuccessMsg('');
      onCouponChange(null);
    } else {
      validateCoupon(code);
    }
  };

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<unknown>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const result = couponValidationResults.data?.data;
    if (result && result.valid && result.coupon) {
      onCouponChange(result.coupon);
      setIsCouponApplied(true);
      setSuccessMsg(`¡Se aplicó un cupón del ${result.coupon.value * 100}%!`);
    }
  }, [couponValidationResults]);

  const getErrMsg = () => {
    if (couponValidationResults?.data?.data) {
      if (!couponValidationResults.data.data.valid) {
        const { reason } = couponValidationResults.data.data;
        if (reason === 'sold out') {
          return 'Cupón agotado';
        }
        return 'Cupón no es válido';
      }
    }
    return '';
  };

  return (
    <Accordion title="Tengo cupón de descuento" isExpanded={loadedCode !== null} titleClassName='text-lg font-semibold p-[12px]'>
      <div>
        <div className="flex flex-col md:flex-row justify-center items-start gap-[20px] self-stretch px-[12px] flex-1">
          <div className="w-full md:w-3/4">
            <Input
              id="code"
              label="Ingrese aquí su cupón"
              value={code}
              onKeyDown={handleKeyDown}
              onChange={handleCodeChange}
              errMsg={getErrMsg()}
              disabled={isCouponApplied}
              additionalClasses='h-12 w-full'
            />
          </div>
          <div className="w-full md:w-1/4">
            <Button
              type="button"
              variant="secondary"
              onClick={handleApplyDiscountClick}
              onKeyDown={handleKeyDown}
              loading={couponValidationResults.isLoading}
              className="w-full"
            >
              {isCouponApplied ? 'Cancelar' : 'Aplicar'}
            </Button>
          </div>
        </div>
        <span className="flex items-center justify-center text-green-500 white-text-shadow">{successMsg}</span>
      </div>
    </Accordion>
  );
};

export default GotCoupon;
