import { Fragment, useState } from 'react';
import { PiWarningCircleLight } from 'react-icons/pi';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import toast from 'react-hot-toast';
import SortableTable from './atoms/SortableTable';
import MenuDropdown from './atoms/MenuDropdown';
import CustomModal from './atoms/CustomModal';
import { useDeleteReferralMutation } from '../store';
import Button from './atoms/Button';
import IconInfoCard from './atoms/IconInfoCard';
import { ReferralDTO } from '../types/Referral';
import CreateEditReferral from './CreateEditReferral';
import config from '../config/config';
import { formatCurrency } from '../utils/shared';
import useGetSelectedBusiness from '../hooks/useGetSelectedBusiness';

interface Props {
  referrals: ReferralDTO[];
  eventId: number;
  businessId: number;
}

const ReferralsTable: React.FC<Props> = ({ referrals, eventId, businessId }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);
  const [actionBar, setActionBar] = useState(<div></div>);
  const [deleteReferral, resultsDelete] = useDeleteReferralMutation();
  const { business } = useGetSelectedBusiness();

  const handleConfirmDeleteReferral = (referral: ReferralDTO) => {
    deleteReferral({
      businessId,
      eventId: +eventId,
      referralId: referral.id,
    });
    setShowModal(false);
  };

  const handleClose = () => setShowModal(false);

  const handleDeleteReferral = (referral: ReferralDTO) => {
    setModalBody(
      <IconInfoCard
        iconColor="text-red-600"
        icon={PiWarningCircleLight}
        title="¿Deseas eliminar este referido?"
        info="Una vez eliminado desaparecerá del listado."
      />,
    );
    setActionBar(
      <div className="w-full">
        <Button
          variant="danger"
          onClick={() => handleConfirmDeleteReferral(referral)}
          className="text-center w-full mt-2"
        >
          Eliminar
        </Button>
      </div>,
    );
    setShowModal(true);
  };

  const handleEditReferral = (referral: ReferralDTO) => {
    setModalBody(
      <Fragment>
        <div className="flex flex-col justify-center items-center mb-5">
          <h1 className="text-xl font-medium mb-4">Editar referido</h1>
          <p className="text-gray-400 text-sm text-center">
            Podrás realizar las ediciones que necesites en este referido
          </p>
        </div>
        <CreateEditReferral referral={referral} onSuccess={handleClose} />
      </Fragment>,
    );
    setShowModal(true);
  };

  const handleCopyReferralLink = (referral: ReferralDTO) => {
    const domain = business?.nickname ? `${business.nickname}.${config.domain}` : config.baseDomain;
    const link = business?.nickname ? `${domain}?ref=${referral.code}` : `${domain}/events/${eventId}?ref=${referral.code}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success('Link copiado al portapapeles');
      })
      .catch((err) => {
        console.error('Error al copiar al portapapeles: ', err);
      });
  };

  const dropDownOptions = [
    {
      label: 'Copiar link',
      onClick: handleCopyReferralLink,
    },
    {
      label: 'Editar',
      onClick: handleEditReferral,
    },
    {
      label: 'Eliminar',
      onClick: handleDeleteReferral,
    },
  ];

  const tableConfig = [
    {
      label: '',
      render: (referral: ReferralDTO) => <div className='flex justify-center items-center'> {referral.active ? <AiFillCheckCircle className=' text-green-600' /> : <AiFillCloseCircle className='text-red-600' />}</div>,
    },
    {
      label: 'Código',
      render: (referral: ReferralDTO) => referral.code,
      sortValue: (referral: ReferralDTO) => referral.code,
    },
    {
      label: 'Nombre',
      render: (referral: ReferralDTO) => referral.name,
      sortValue: (referral: ReferralDTO) => referral.name,
    },
    {
      label: 'Tickets',
      render: (referral: ReferralDTO) => `${referral.tickets_sold}`,
    },
    {
      label: 'Total',
      render: (referral: ReferralDTO) => `${formatCurrency(referral.amount_sold)}`,
    },
    {
      label: '',
      render: (referral: ReferralDTO) => (
        <div>
          <MenuDropdown<ReferralDTO>
            options={dropDownOptions}
            data={referral}
            loading={resultsDelete.isLoading}
          />
        </div>
      ),
    },
  ];

  const keyFn = (referral: ReferralDTO) => `${referral.id}`;

  return (
    <div>
      <SortableTable data={referrals} config={tableConfig} keyFn={keyFn} pagination={false} search={false}/>
      <CustomModal
        actionBar={actionBar}
        onRequestClose={handleClose}
        isOpen={showModal}
      >
        {modalBody}
      </CustomModal>
    </div>
  );
};

export default ReferralsTable;
