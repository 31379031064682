import React from 'react';
import classNames from 'classnames';

type StatusType = 'danger' | 'warning' | 'success' | 'info' | 'default';

interface Props {
  type?: StatusType;
  children: React.ReactNode;
  className?: string;
  center?: boolean;
}

const TextStatus: React.FC<Props> = ({
  type = 'default',
  children,
  className,
  center = false,
}) => {
  const statusClasses = {
    danger: 'text-red-600 bg-red-50',
    warning: 'text-yellow-600 bg-yellow-50',
    success: 'text-green-600 bg-green-50',
    info: 'text-blue-600 bg-blue-50',
    default: 'text-gray-600 bg-gray-50',
  };

  return (
    <span
      className={classNames(
        'inline-flex px-2 py-1 text-sm font-medium rounded-md',
        statusClasses[type],
        center && 'justify-center items-center',
        className,
      )}
    >
      {children}
    </span>
  );
};

export default TextStatus;
