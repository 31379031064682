import SortableTable from './atoms/SortableTable';
import InvitationDTO from '../models/invitation';
import { displayUserPermissions } from '../models/user';
import MenuDropdown from './atoms/MenuDropdown';
import { useDeleteInvitationMutation } from '../store';

interface Props {
  invitations: InvitationDTO[];
}

const InvitationsTable: React.FC<Props> = ({ invitations }) => {
  const [deleteInvitation, { isLoading: isDeletingInvitation }] = useDeleteInvitationMutation();

  const config = [
    {
      label: 'Correo',
      render: (invitation: InvitationDTO) => invitation.email,
    },
    {
      label: 'Permisos',
      render: (invitation: InvitationDTO) => displayUserPermissions[
        invitation.permissions as keyof typeof displayUserPermissions
      ],
    },
    {
      label: '',
      render: (invitation: InvitationDTO) => (
        <div>
          <MenuDropdown<InvitationDTO>
            options={[
              {
                label: 'Eliminar',
                onClick: (inv: InvitationDTO) => deleteInvitation({ businessId: inv.business_id, invitationId: inv.id }),
              },
            ]}
            data={invitation}
            loading={isDeletingInvitation}
          />
        </div>
      ),
    },
  ];

  const keyFn = (invitation: InvitationDTO) => `${invitation.id}`;

  return (
    <div>
      <SortableTable data={invitations} config={config} keyFn={keyFn} pagination={false} search={false}/>
    </div>
  );
};

export default InvitationsTable;
