import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import QuestionsForm from './QuestionsForm';
import { RootState } from '../store';
import TextTitleCard from '../components/atoms/TitleTextCard';
import { useGetEventQuestionsQuery } from '../store/apis/events';
import FormSkeleton from '../components/FormSkeleton';
import Button from '../components/atoms/Button';
import useIsBackOffice from '../hooks/useIsBackoffice';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

const QuestionsPage: React.FC = () => {
  const navigate = useNavigateWithQuery();
  const isBackOffice = useIsBackOffice();
  const orderItems = useSelector(
    (state: RootState) => state.orders.orderItemData,
  );

  const event = useSelector((state: RootState) => state.events.selectedEvent);

  const { data: questions, isFetching } = useGetEventQuestionsQuery(
    { eventId: event?.id ?? 0 },
    { skip: !event },
  );

  useEffect(() => {
    if (!event || !orderItems) {
      navigate('../../');
    }
  }, [event, orderItems, navigate]);

  return (
    <div>
      <div className="mb-5">
        <TextTitleCard>
          <div className="text-center">
            <div className="font-medium">
              ¡Estás a un paso de tener tu entrada!
            </div>
            <div>
              El organizador necesita algunos datos para conocerte mejor
            </div>
            {isBackOffice && (
              <div className="flex justify-center align-items-center mt-2">
                <Button variant="primary" onClick={() => navigate('../checkout?customized=true&skip-poll=true')}>
                  Omitir
                </Button>
              </div>
            )}
          </div>
        </TextTitleCard>
      </div>
      {!isFetching && (
        <QuestionsForm questions={questions ?? []} orderItems={orderItems} />
      )}
      {isFetching && <FormSkeleton />}
    </div>
  );
};
export default QuestionsPage;
