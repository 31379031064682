import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { combineReducers } from 'redux';
import { ordersApi } from './apis/orders';
import { eventsApi } from './apis/events';
import { ticketsApi } from './apis/tickets';
import { businessesApi } from './apis/business';
import { eventsReducer } from './slices/events';
import { usersReducer } from './slices/users';
import { usersApi } from './apis/users';
import { businessReducer } from './slices/business';
import { ordersReducer } from './slices/orders';
import { ticketsReducer } from './slices/tickets';
import { couponsApi } from './apis/coupons';
import { authReducer } from './slices/auth';
import { referralsApi } from './apis/referrals';
import { commonApi } from './apis/common';

export const store = configureStore({
  reducer: {
    [ordersApi.reducerPath]: ordersApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [ticketsApi.reducerPath]: ticketsApi.reducer,
    [businessesApi.reducerPath]: businessesApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [couponsApi.reducerPath]: couponsApi.reducer,
    [referralsApi.reducerPath]: referralsApi.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    events: eventsReducer,
    users: usersReducer,
    businesses: businessReducer,
    orders: ordersReducer,
    tickets: ticketsReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(ordersApi.middleware)
    .concat(eventsApi.middleware)
    .concat(ticketsApi.middleware)
    .concat(businessesApi.middleware)
    .concat(usersApi.middleware)
    .concat(couponsApi.middleware)
    .concat(referralsApi.middleware)
    .concat(commonApi.middleware),
});

const rootReducer = combineReducers({
  events: eventsReducer,
  users: usersReducer,
  businesses: businessReducer,
  orders: ordersReducer,
  tickets: ticketsReducer,
  auth: authReducer,
});

setupListeners(store.dispatch);

export {
  useCreateOrderMutation,
  useGetEventOrdersQuery,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useGetEventOrdersExportQuery,
  useUpdateOrderItemMutation,
  useDeleteOrderItemMutation,
  useUpdateOrderStatusMutation,
  useDecodeQrCodeQuery,
  useUpdateUsedOrderItemMutation,
  useSendOrderEmailMutation,
  useGetOrderByCustomIdQuery,
  useGetSellerOrdersQuery,
  useGetSellerSummaryQuery,
  useGetOneSellerSummaryQuery,
  useGetOrderTotalsQuery,
  useGetEventOrdersReportQuery,
} from './apis/orders';

export {
  useGetAllBusinessEventsQuery,
  useGetEventByIdQuery,
  useGetBusinessEventsQuery,
  useGetCurrentAgentQuery,
  useGetAllEventsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useGetEventUsersQuery,
  useAddUserToEventMutation,
  useDeleteUserEventMutation,
  useAddEventTransactionMutation,
  useDeleteEventTransactionMutation,
  useGetEventCapacityQuery,
  useCountSellerQrCodeMutation,
  useGetSellerEventQrQuery,
  useLazyGetSellerEventQrQuery,
  useUpdateUserEventMutation,
  useGetEventBalanceQuery,
  useCreateWireTransferMutation,
  useImportSellersToEventMutation,
  useReserveSeatMutation,
  useFreeSeatMutation,
} from './apis/events';

export {
  useGetCouponByIdQuery,
  useGetAllCouponsQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponMutation,
  useCheckCouponValidationMutation,
} from './apis/coupons';

export {
  useGetReferralByIdQuery,
  useGetAllReferralsQuery,
  useCreateReferralMutation,
  useUpdateReferralMutation,
  useDeleteReferralMutation,
} from './apis/referrals';

export {
  useGetMPAuthLinkQuery,
} from './apis/common';

export {
  useGetAllTicketsQuery,
  useGetTicketByIdQuery,
  useCreateTicketMutation,
  useUpdateTicketMutation,
  useDeleteTicketMutation,
  useReserveTicketsMutation,
  useCreateReservationMutation,
  useGetReservationAndSeatsQuery,
} from './apis/tickets';

export {
  useGetAllBusinessesQuery,
  useGetBusinessByIdQuery,
  useCreateBusinessMutation,
  useUpdateBusinessMutation,
  useGetBusinessUsersQuery,
  useGetBusinessInvitationsQuery,
  useCreateInvitationMutation,
  useGetEmailInvitationsQuery,
  useAcceptInvitationMutation,
  useGetBusinessBySubdomainQuery,
  useDeleteInvitationMutation,
  useRemoveUserFromBusinessMutation,
  useAuthorizeMercadoPagoMutation,
  useGetPaymentConfigQuery,
} from './apis/business';

export {
  useLogInMutation,
  useGetUserByFirebaseIdQuery,
  useCreateUserMutation,
  useGetUserByConfirmationCodeQuery,
  useVerifyUserMutation,
  useGetUserBusinessesQuery,
  useValidateGoogleLoginMutation,
  useLazyGetUserByFirebaseIdQuery,
} from './apis/users';

export {
  setCreatedOrder, setOrderItemData, setPollData, setReservation, clearReservation, selectReservation,
} from './slices/orders';
export { setBusiness } from './slices/business';
export { setAuthToken, setSelectedBusiness, setUserBusinesses } from './slices/users';
export { setTickets, setTicketsSelected } from './slices/tickets';
export { logout, loginSuccess, loadAuth } from './slices/auth';
export type RootState = ReturnType<typeof rootReducer>;
