import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useState } from 'react';
import { FaGoogle } from 'react-icons/fa';
import { signInWithRedirect, signInWithPopup } from 'firebase/auth';
import Input from './atoms/Input';
import Button from './atoms/Button';
import {
  useCreateUserMutation,
  useLazyGetUserByFirebaseIdQuery,
} from '../store';
import UserDTO from '../models/user';
import { toastError } from '../utils/toasts';
import errorResponseToString from '../utils/errorResponseToString';
import config from '../config/config';
import { googleAuth, auth } from '../config/firebase-config';

interface Props {
  onSuccess: (user: UserDTO) => void;
  onGoogle: () => void;
}

interface FormData {
  name: string;
  email: string;
  emailConfirm: string;
  password: string;
  passwordConfirm: string;
}

const noSpaces = (value: string) => !/\s/.test(value);
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validationSchema = yup.object<FormData>().shape({
  name: yup
    .string()
    .required('Debes ingresar tu nombre'),
  email: yup
    .string()
    .required('Debes ingresar un correo')
    .matches(emailRegex, 'Este correo no está bien')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
  emailConfirm: yup
    .string()
    .required('Por seguridad, debes confirmar tu correo')
    .oneOf([yup.ref('email')], 'Los correos no coinciden')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
  password: yup
    .string()
    .required('Debes ingresar una contraseña')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
  passwordConfirm: yup
    .string()
    .required('Por seguridad, debes confirmar tu contraseña')
    .oneOf([yup.ref('password')], 'Los correos no coinciden')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
});

const SignUpForm: React.FC<Props> = ({ onSuccess, onGoogle }) => {
  const [createUser, results] = useCreateUserMutation();
  const [errorMsg, setErrorMsg] = useState('');
  const form = useForm<FormData>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    register, handleSubmit, formState,
  } = form;

  const handleSignUp = async () => {
    const { name: username, email, password } = form.getValues();
    try {
      const response = await createUser({ username, email, password });
      if ('error' in response) {
        return setErrorMsg(errorResponseToString(response.error));
      }
      if ('data' in response && response.data) {
        return onSuccess(response.data);
      }
      throw new Error(`no response data. response is ${JSON.stringify(response)}`);
    } catch (e) {
      console.error(e);
      return toastError('Ocurrió un error');
    }
  };

  const [getUserByFirebaseId] = useLazyGetUserByFirebaseIdQuery();
  const [firebaseAuthLoading, setFirebaseAuthLoading] = useState(false);

  const handleSignInWithGoogle = async () => {
    try {
      setFirebaseAuthLoading(true);
      if (config.env !== 'local') {
        return await signInWithRedirect(auth, googleAuth);
      }
      // local no funciona redirect
      const { user: fbUser } = await signInWithPopup(auth, googleAuth);
      const firebaseId = fbUser.uid;

      if (!firebaseId) {
        throw new Error('Failed to get Firebase ID');
      }
      if (!fbUser.email) {
        throw new Error('Google User has no email');
      }

      const { data: userByFbId } = await getUserByFirebaseId({ firebaseId });
      if (userByFbId) {
        onGoogle();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFirebaseAuthLoading(false);
    }
    return null;
  };

  return (
    <div className="flex flex-col items-center admin p-6 my-10 bg-white rounded-lg shadow-sm max-w-[820px] mx-auto">
      <div className="flex flex-col items-center mt-10">
        <div className="h-12 w-64">
          <img src="/avantti-header.svg" className="w-full" />
        </div>
        <h1 className="text-2xl mt-8">Crear usuario</h1>
        <p className="text-center mb-4">Crea una cuenta en Avantti</p>
      </div>
      <form className="flex flex-col items-center gap-6 w-full max-w-[358px]" onSubmit={handleSubmit(handleSignUp)}>
        <Input
          label="Nombre"
          labelAbove={true}
          id="name"
          {...register('name', {
            required: true,
          })}
          errMsg={formState.errors.name?.message}
        />
        <Input
          label="Correo"
          labelAbove={true}
          id="email"
          {...register('email', {
            required: true,
          })}
          errMsg={formState.errors.email?.message}
        />
        <Input
          label="Confirmar correo"
          labelAbove={true}
          id="emailConfirm"
          {...register('emailConfirm', {
            required: true,
          })}
          errMsg={formState.errors.emailConfirm?.message}
        />
        <Input
          label="Contraseña"
          labelAbove={true}
          id="password"
          type='password'
          {...register('password', {
            required: true,
          })}
          errMsg={formState.errors.password?.message}
        />
        <Input
          label="Confirmar contraseña"
          labelAbove={true}
          id="passwordConfirm"
          type='password'
          {...register('passwordConfirm', {
            required: true,
          })}
          errMsg={formState.errors.passwordConfirm?.message}
        />
        {errorMsg && <p className="text-red-500">{errorMsg}</p>}
        <div className="flex justify-center mt-2 w-[216px]">
          <Button
            loading={results.isLoading}
            className="text-lg"
            disabled={!formState.isValid}
            type="submit"
            variant="primary"
          >
            Crear usuario
          </Button>
        </div>
      </form>
      <div className="flex justify-center align-items-center mt-2">
        <div className="text-center">o</div>
      </div>
      <div className="flex justify-center align-items-center mt-2">
        <Button
          loading={firebaseAuthLoading}
          disabled={firebaseAuthLoading}
          className="text-lg"
          type="button"
          onClick={handleSignInWithGoogle}
          variant="secondary"
        >
          <div className="flex items-center align-items-center">
            <FaGoogle className='mr-2' />
            <span>Ingresar con Google</span>
          </div>
        </Button>
      </div>
    </div>
  );
};
export default SignUpForm;
