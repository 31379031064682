import { useEffect } from 'react';
import { HiLink } from 'react-icons/hi';
import { FaBalanceScaleLeft } from 'react-icons/fa';
import EventMenuOption from '../components/EventMenuOption';
import authenticate from '../utils/authenticate';
import { UserRole } from '../models/user';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import useGetUserRole from '../hooks/useGetUserRole';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';
import LoadingLottie from '../components/atoms/LoadingLottie';
import Button from '../components/atoms/Button';
import copyLinkToClipboard from '../utils/copyLinkToClipboard';
import config from '../config/config';

interface Options {
  title: string;
  info: string;
  buttonLabel: string;
  redirectPath: string;
  allowedUsers: UserRole[];
  hidden?: boolean;
  image: string;
}

const EventMenu: React.FC = () => {
  const navigate = useNavigateWithQuery();
  const { event, eventLoading } = useGetSelectedEvent();
  const role = useGetUserRole();

  useEffect(() => {
    if (role === 'SELLER') {
      navigate('orders', { replace: true });
    } else if (role === 'SCANNER') {
      navigate('scanner', { replace: true });
    }
  }, [role, navigate]);

  const options: Options[] = [
    {
      title: 'Asistentes',
      info: 'En este apartado encontrarás la acción de aceptar solicitudes de entradas, y podrás visualizar los asistentes al evento.',
      buttonLabel: 'Ver lista',
      redirectPath: 'orders',
      allowedUsers: ['EDITOR', 'ADMIN'],
      image: '/event-orders.svg',
    },
    {
      title: 'Entradas',
      info: 'Aquí podras crear los diferentes tipos de entradas de tu evento, configurar su precio y cantidad de cada una.',
      buttonLabel: 'Editar entradas',
      redirectPath: 'tickets',
      allowedUsers: ['ADMIN'],
      image: '/event-tickets.svg',
    },
    {
      title: 'Reportes',
      info: 'En este apartado podrás descargar reportes de asistencia, pagos y respuestas de encuestas.',
      buttonLabel: 'Ver reportes',
      redirectPath: 'sales-report',
      allowedUsers: ['ADMIN'],
      image: '/event-referrals.svg',
    },
    {
      title: 'Cupones',
      info: 'Aquí podras crear y modificar los cupones de descuento de tu evento.',
      buttonLabel: 'Editar cupones',
      redirectPath: 'coupons',
      allowedUsers: ['ADMIN'],
      image: '/event-coupons.svg',
    },
    {
      title: 'Referidos',
      info: 'Aquí ver y configurar los referidos del evento.',
      buttonLabel: 'Ver referidos',
      redirectPath: 'referrals',
      allowedUsers: ['ADMIN'],
      image: '/event-referrals.svg',
    },
    {
      title: 'Encuestas',
      info: 'Aquí podras ver las respuestas a las preguntas de tu evento.',
      buttonLabel: 'Ver respuestas',
      redirectPath: 'polls',
      allowedUsers: ['ADMIN'],
      hidden: !event?.has_polls,
      image: '/event-orders.svg',
    },
    {
      title: 'Vendedores',
      info: 'Aquí podras configurar los vendedores de este evento y ver sus ventas',
      buttonLabel: 'Ver vendedores',
      redirectPath: 'sellers',
      allowedUsers: ['ADMIN'],
      image: '/event-orders.svg',
    },
    {
      title: 'Pagos',
      info: 'Aquí podrás ver información de los pagos del evento',
      buttonLabel: 'Ver pagos',
      redirectPath: 'payments',
      allowedUsers: ['ROOT'],
      image: '/event-orders.svg',
    },
  ];

  if (!event || eventLoading) {
    return <LoadingLottie />;
  }

  const handleBalanceClick = () => {
    navigate('balance');
  };

  const handleManualSellClick = () => {
    navigate(`/admin/${event.business_id}/events/${event.id}/orders/new/tickets`);
  };

  const handleScannerClick = () => {
    navigate('scanner');
  };

  const handleEditEventClick = () => {
    navigate('edit');
  };

  return (
    <div className="flex flex-col gap-8 mt-10">
      <div className="flex items-center gap-4 self-stretch">
        <div className="text-3xl text-gray-700 font-medium">Eventos</div>
        <img src={'/event-svg-2.svg'} alt="" />
        <img src={'/event-svg-1.svg'} alt="" />
      </div>
      <div className="flex flex-col sm:flex-row justify-between gap-2">
        <div className="flex items-center gap-4">
          <div className="flex flex-col">
            <h1 className="flex items-center gap-2 text-2xl">{event.name}
              <div className="flex items-center justify-center w-10">
                <Button variant="tertiary"
                  onClick={() => copyLinkToClipboard(`${config.baseDomain}/events/${event.id}`)}
                >
                  <HiLink className="text-xl" />
                </Button>
              </div>
              <div className="flex items-center justify-center w-10">
                {role === 'ROOT' && (
                  <Button onClick={handleBalanceClick} variant="secondary" className="inline-flex items-center gap-2 brightness-0">
                    <FaBalanceScaleLeft className="text-xl" />
                  </Button>
                )}
              </div>
            </h1>
            <h2 className="text-lg text-gray-500">{event?.business?.name}</h2>
          </div>
        </div>

        <div className="flex flex-row gap-2">
          {(role === 'ADMIN' || role === 'ROOT') && (
            <Button onClick={handleManualSellClick} variant="secondary">
              <div className="flex items-center gap-2 justify-center p-2">
                <img src="/coins-hand.svg" alt="sell" className="w-5 h-5 brightness-0" />
                <p className="hidden sm:block whitespace-nowrap">Vender manualmente</p>
              </div>
            </Button>
          )}
          {(role === 'ADMIN' || role === 'ROOT' || role === 'SCANNER') && (
            <Button onClick={handleScannerClick} variant="secondary">
              <div className="flex items-center gap-2 justify-center p-2">
                <img src="/qr-code-02.svg" alt="scanner" className="w-5 h-5 brightness-0" />
                <p className="hidden sm:block whitespace-nowrap">Abrir Lector de entradas</p>
              </div>
            </Button>
          )}
          {(role === 'ADMIN' || role === 'ROOT') && (
            <Button onClick={handleEditEventClick} variant="secondary">
              <div className="flex items-center gap-2 justify-center p-2">
                <img src="/edit-3.svg" alt="edit" className="w-5 h-5 brightness-0" />
                <p className="hidden sm:block whitespace-nowrap">Editar evento</p>
              </div>
            </Button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {options.map(
          (option) => !option.hidden && (
            <div key={option.title} className="mb-3">
              {authenticate(option.allowedUsers, role) && (
                <EventMenuOption
                  id={event?.id ?? 0}
                  title={option.title}
                  info={option.info}
                  buttonLabel={option.buttonLabel}
                  redirectPath={option.redirectPath}
                  image={option.image}
                />
              )}
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default EventMenu;
