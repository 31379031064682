import Button from './atoms/Button';

interface TicketProps {
  price: number;
  onClick: () => void;
}

const Ticket: React.FC<TicketProps> = ({ price, onClick }) => (
  <div className="flex items-center w-full">
    <div className="flex w-[10px] h-[132px] flex-col justify-center flex-shrink-0">
      <img src="/ticket-frame.svg" alt="ticket" className="w-[10px] h-[116px]" />
    </div>
    <div className="bg-primary-base w-[4px] h-[116px]"></div>
    <div className="flex items-center gap-4 justify-center flex-col w-5/6 h-[132px] shrink-0 rounded-l-lg bg-white shadow-sm">
      <div className="text-lg font-semibold">Entradas desde ${price}</div>
      <div>
        <Button variant="primary" onClick={onClick}><div className="whitespace-nowrap">Comprar entradas</div></Button>
      </div>
    </div>
    <div className="w-1/6 h-[132px] bg-background-black rounded-r-lg bg-[#222] shadow-sm"></div>
    <div className="bg-primary-base w-[4px] h-[116px]"></div>
    <div className="flex w-[10px] h-[132px] flex-col rotate-180 justify-center flex-shrink-0">
      <img src="/ticket-frame.svg" alt="ticket" className="w-[10px] h-[116px]" />
    </div>
  </div>
);

export default Ticket;
