import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from './atoms/Input';
import { displayUserPermissions, userPermissionsArray } from '../models/user';
import Select from './atoms/Select';
import Button from './atoms/Button';
import { useCreateInvitationMutation } from '../store';
import { toastSucces } from '../utils/toasts';

interface Props {
  businessId: number;
  onSuccess: () => void;
}

interface FormData {
  email: string;
  permissions: string;
}

const noSpaces = (value: string) => !/\s/.test(value);
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validationSchema = yup.object<FormData>().shape({
  email: yup
    .string()
    .required('Debes ingresar un correo')
    .matches(emailRegex, 'Este correo no está bien')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
  permissions: yup
    .string()
    .required('Debes seleccionar una opción')
    .oneOf(userPermissionsArray, 'La opción seleccionada no es válida'),
});

const InviteUser: React.FC<Props> = ({ businessId, onSuccess }) => {
  const form = useForm<FormData>({
    resolver: yupResolver<FormData>(validationSchema),
  });

  const { control, handleSubmit, formState } = form;

  const [createInvitation, createInvitationResults] = useCreateInvitationMutation();

  const handleSendInvite = async () => {
    try {
      const result = await createInvitation({ businessId, email: form.getValues('email'), permissions: form.getValues('permissions') });
      if ('data' in result) {
        toastSucces('Invitación enviada con éxito');
        onSuccess();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <form className="w-full h-full" onSubmit={handleSubmit(handleSendInvite)}>
        <div className="mb-5">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                label="Correo electrónico"
                labelAbove={true}
                id="email"
                {...field}
                onChange={(e) => field.onChange(e.target.value.replace(/\s/g, ''))
                }
                errMsg={formState.errors.email?.message}
              />
            )}
          />
        </div>
        <div className="mb-5">
          <Controller
            name="permissions"
            control={control}
            render={({ field }) => (
              <Select
                label="Nivel de permisos"
                id="permissions"
                options={userPermissionsArray.filter((p) => p !== 'USER').map((p) => ({
                  label: displayUserPermissions[p as keyof typeof displayUserPermissions],
                  value: p,
                }))}
                {...field}
              />
            )}
          />
        </div>
        <div className="flex justify-center align-items-center">
          <Button className="w-full" variant="primary" type='submit' loading={createInvitationResults.isLoading}>
            Enviar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InviteUser;
