import { useState, useEffect } from 'react';
import SortableTable from './atoms/SortableTable';
import TicketDTO, { TicketPublicSaleStatus } from '../models/ticket';
import Counter from './Counter';
import { formatCurrency } from '../utils/shared';
import TextStatus from './atoms/TextStatus';

export interface TicketSelection {
  ticket: TicketDTO;
  quantity: number;
  seat_name?: string;
  seat_id?: number;
}
interface Props {
  onTicketsChange: (ticketsSelection: TicketSelection[]) => void;
  tickets: TicketDTO[];
}

const SelectTicketsTable: React.FC<Props> = ({ onTicketsChange, tickets }) => {
  const [ticketsQuantity, setTicketsQuantity] = useState<TicketSelection[]>([]);
  const [sortedTickets, setSortedtickets] = useState<TicketDTO[]>([]);

  if (!tickets) {
    throw new Error('no tickets');
  }

  useEffect(() => {
    setSortedtickets([...tickets].sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()));
  }, [tickets]);

  useEffect(() => {
    if (tickets) {
      setTicketsQuantity(tickets.map((t) => ({ ticket: t, quantity: 0 })));
    }
  }, [tickets]);

  useEffect(() => {
    onTicketsChange(ticketsQuantity.filter((t) => t.quantity > 0));
  }, [ticketsQuantity]);

  useEffect(() => {
    if (tickets) {
      setTicketsQuantity(tickets.map((t) => ({ ticket: t, quantity: 0 })));
    }
  }, [tickets]);

  const handleQuantityUpdate = (id: number, updatedQuantity: number) => {
    const copy = [...ticketsQuantity];
    const i = copy.findIndex((t) => t.ticket.id === id);
    if (i === -1) {
      return null;
    }
    copy[i] = { ...copy[i], quantity: updatedQuantity };
    return setTicketsQuantity(copy);
  };

  const getStatusLabel = (ticket: TicketDTO) => {
    if (ticket.public_sale_status === TicketPublicSaleStatus.SoldOut) {
      return 'Agotado';
    }
    if (ticket.public_sale_status === TicketPublicSaleStatus.ComingSoon) {
      return 'Próximamente';
    }
    return 'Disponible';
  };

  const getStatusClass = (ticket: TicketDTO) => {
    if (ticket.public_sale_status === TicketPublicSaleStatus.SoldOut) {
      return 'danger';
    }
    if (ticket.public_sale_status === TicketPublicSaleStatus.ComingSoon) {
      return 'warning';
    }
    return 'success';
  };

  const config = [
    {
      label: 'Nombre',
      render: (ticket: TicketDTO) => (
        <div className=" flex flex-col align-items-center justify-center">
          <span>{ticket.name}</span>
          <span className="text-xs text-gray-500">
            {ticket.description}
          </span>
          {ticket.total_amount - ticket.amount_taken < 10
            && ticket.total_amount - ticket.amount_taken > 0 && (
              <span className="text-xs text-red-500">
                ¡Solo quedan {ticket.total_amount - ticket.amount_taken}!
              </span>)}
        </div>
      ),
    },
    {
      label: 'Precio',
      render: (ticket: TicketDTO) => (ticket.has_open_price ? '💰💰💰' : `${formatCurrency(ticket.price * ticket.items_create)}`),
    },
    {
      label: 'Cantidad',
      render: (ticket: TicketDTO) => (ticket.public_sale_status === TicketPublicSaleStatus.Available ? (
        <div className="flex">
          <Counter
            key={ticket.id}
            upperLimit={Math.min(
              ticket.max_per_order,
              ticket.total_amount - ticket.amount_taken,
            )}
            onValueChange={(newValue: number) => handleQuantityUpdate(ticket.id, newValue)
            }
          />
        </div>
      ) : (
        <div className="flex">
          <TextStatus type={getStatusClass(ticket)}>
            {getStatusLabel(ticket)}
          </TextStatus>
        </div>
      )),
    },
  ];

  const keyFn = (ticket: TicketDTO) => ticket.id;

  return (
    <SortableTable
      data={sortedTickets}
      config={config}
      keyFn={keyFn}
      pagination={false}
      search={false}
    />
  );
};

export default SelectTicketsTable;
