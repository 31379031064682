import { TicketSummaryDTO } from '../models/order';
import { formatCurrency } from '../utils/shared';
import SortableTable from './atoms/SortableTable';

interface Props {
  summaries: TicketSummaryDTO[];
}

const TicketsSummaryTable: React.FC<Props> = ({ summaries }) => {
  const config = [
    {
      label: 'Ticket',
      render: (s: TicketSummaryDTO) => s.ticket_name,
    },
    {
      label: 'Precio',
      render: (s: TicketSummaryDTO) => formatCurrency(s.ticket_price),
    },
    {
      label: 'Cantidad',
      render: (s: TicketSummaryDTO) => s.count,
    },
    {
      label: 'Total',
      render: (s: TicketSummaryDTO) => formatCurrency(s.total),
    },
  ];
  return (
    <div>
      <SortableTable data={summaries} config={config} keyFn={(s) => s.ticket_id.toString()} pagination={false} search={false} />
    </div>
  );
};

export default TicketsSummaryTable;
