import Button from './Button';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  subtitle?: string;
  addButtonAction?: () => void | undefined;
  additionalCardClasses?: string;
}

const TextTitleCard: React.FC<Props> = ({
  title,
  subtitle,
  children,
  addButtonAction,
  additionalCardClasses,
}) => (
  <div
    className={`${'rounded-lg p-4 bg-white shadow w-full'} ${additionalCardClasses}`}
  >
    <div className="flex justify-between">
      {title && <h2 className="text-lg font-medium">{title}</h2>}
      {addButtonAction && <div className="w-12"><Button variant="secondary" onClick={addButtonAction}>+</Button></div>}
    </div>
    {subtitle && <p className="text-xs text-gray-500 mb-3">{subtitle}</p>}
    {title && <hr className="my-3" />}
    <div className="flex flex-col text-md">{children}</div>
  </div>
);

export default TextTitleCard;
