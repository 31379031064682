import {
  Button,
  Card, Col, Row, Space, Table, TableProps,
} from 'antd';
import { IoClose } from 'react-icons/io5';
import { GoSync } from 'react-icons/go';
import { Text } from './Text';
import { DisplaySeatDTO } from '../models/seat';

interface NumeratedOrderDetailsProps {
  displaySeats: Array<DisplaySeatDTO>,
  onSeatsUpdate: (selectedSeats: DisplaySeatDTO[]) => void,
  isLoading?: boolean;
}

interface DataType {
  key: string;
  sector: Array<string>;
  place: string;
}

const NumeratedOrderDetails = ({ displaySeats, onSeatsUpdate, isLoading }: NumeratedOrderDetailsProps) => {
  const data: DataType[] = displaySeats.map((seat) => ({
    key: seat.id.toString(),
    sector: ['Entrada', seat.sector_name],
    place: seat.seat_name,
  }));

  const total = displaySeats.reduce((a, b) => a + b.ticket_price, 0);

  const deleteSelected = (record: DataType) => {
    const updatedSeats = displaySeats.filter((seat) => seat.id !== Number(record.key));
    onSeatsUpdate(updatedSeats);
  };

  const columns: TableProps<DataType>['columns'] = [
    {
      title: <Text size='xs' color='text-neutral-600' medium>Sector</Text>,
      dataIndex: 'sector',
      key: 'sector',
      width: '32%',
      render: (text: Array<string>) => <Space size={0} direction='vertical'>
      <Text size='sm' medium>{text[0]}</Text>
      <Text size='xs' color='text-neutral-600'>{text[1]}</Text>
    </Space>,
    },
    {
      title: <Text size='xs' color='text-neutral-600' medium>Lugar</Text>,
      dataIndex: 'place',
      key: 'place',
      render: (text) => <Text size='sm' color='text-neutral-600'>{text}</Text>,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div>
          {isLoading
            ? <GoSync className="animate-spin" />
            : <Button type='text' onClick={() => deleteSelected(record)}>
            <IoClose className='text-neutral-700' size={24}/>
          </Button>
            }
        </div>
      ),
      width: 10,
    },
  ];

  const footer = () => (
  <Row>
    <Col span={8} className='text-start'><Text size='sm' medium>Subtotal</Text></Col>
    <Col className='text-start'>
      <Text size='sm' medium>${total}</Text>
    </Col>
  </Row>
  );

  return (
    <Card
      className='text-center overflow-hidden'
      styles={{ body: { padding: 0 } }}
      title={
        <Text size='lg' medium>Detalle de compra</Text>
      }
    >
      <Table<DataType>
        className={data.length ? '' : 'padding-none'}
        columns={columns}
        dataSource={data}
        footer={footer}
        pagination={false}
        locale={{ emptyText: '' }}
      />
    </Card>
  );
};

export default NumeratedOrderDetails;
