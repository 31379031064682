import React from 'react';

const OrderTotalSkeleton = () => (
    <div className="flex flex-col">
      <div className="p-[16px] flex flex-row justify-between">
        <div className="h-[20px] w-[80px] bg-gray-200 animate-pulse rounded"></div>
        <div className="h-[20px] w-[100px] bg-gray-200 animate-pulse rounded"></div>
      </div>
      <div className="p-[16px] flex flex-row justify-between">
        <div className="h-[20px] w-[120px] bg-gray-200 animate-pulse rounded"></div>
        <div className="h-[20px] w-[100px] bg-gray-200 animate-pulse rounded"></div>
      </div>
      <div className="p-[16px] flex flex-row justify-between">
        <div className="h-[20px] w-[100px] bg-gray-200 animate-pulse rounded font-medium"></div>
        <div className="h-[20px] w-[120px] bg-gray-200 animate-pulse rounded"></div>
      </div>
    </div>
);

export default OrderTotalSkeleton;
