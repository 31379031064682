import { useState } from 'react';
import { GoChevronUp, GoChevronDown } from 'react-icons/go';

interface AccordionProps {
  children: JSX.Element;
  title: string;
  titleClassName?: string;
  isExpanded?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  children, title, isExpanded, titleClassName,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const handleClick = () => {
    setExpanded((e) => !e);
  };

  const icon = expanded ? <GoChevronUp className='text-2xl' /> : <GoChevronDown className='text-2xl' />;

  return (
    <div className="rounded-lg bg-white">
      <div>
        <div className=" rounded-lg shadow items-center cursor-pointer">
          <div
            onClick={() => handleClick()}
            className="flex justify-between items-center p-3"
          >
            <span className={`text-lg font-medium m-0 ${titleClassName}`}>{title}</span>
            {icon}
          </div>
          <div className="p-3" hidden={!expanded}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
