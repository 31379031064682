import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import EventDTO from '../../models/event';
import { eventsApi } from '../apis/events';

interface EventsState {
  selectedEvent: EventDTO | null;
  phoneNumber: string | null;
}

const initialState: EventsState = {
  selectedEvent: null,
  phoneNumber: null,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setSelectedEvent: (state, action: PayloadAction<EventDTO>) => ({ ...state, selectedEvent: action.payload }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      eventsApi.endpoints.getCurrentAgent.matchFulfilled,
      (state, action) => ({
        ...state,
        phoneNumber: action.payload?.phone_number || state.selectedEvent?.phone || null,
      }),
    );
  },
});

export const { setSelectedEvent } = eventsSlice.actions;
export const eventsReducer = eventsSlice.reducer;
