import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { formatCurrency } from '../utils/shared';
import TextTitleCard from './atoms/TitleTextCard';
import { RootState, useGetOneSellerSummaryQuery } from '../store';
import TicketsSummaryTable from './SellersTicketSummary';
import ApprovedOrdersTable from './ApprovedOrdersTable';
import parseOrderToOrderItem from '../utils/parseOrderToOrderItem';
import OrderItem from '../types/OrderItem';
import useGetUserRole from '../hooks/useGetUserRole';
import useGetParams from '../hooks/useGetParams';
import TransactionsTable from './TransactionsTable';
import CustomModal from './atoms/CustomModal';
import AddUserExpense from './AddUserExpense';
import SellerSummary from './SellerSummary';
import { setSellerDetails } from '../store/slices/orders';
import ReferralSummary from './ReferralSummary';

const SellersSummaryDetails: React.FC = () => {
  const summary = useSelector((state: RootState) => state.orders.sellerDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState<OrderItem[]>([]);
  const role = useGetUserRole();
  const { businessId, eventId } = useGetParams();
  const [showModal, setShowModal] = useState(false);

  const { data: refreshedSummary } = useGetOneSellerSummaryQuery(
    {
      businessId,
      eventId,
      sellerId: summary?.seller_id ?? 0,
    },
    {
      skip: !summary?.seller_id,
    },
  );

  useEffect(() => {
    if (refreshedSummary) {
      dispatch(setSellerDetails(refreshedSummary));
    }
  }, [refreshedSummary]);

  useEffect(() => {
    if (summary) {
      setOrders(parseOrderToOrderItem(summary.seller_orders));
    } else {
      console.error('no summary');
      navigate('..');
    }
  }, [summary, navigate]);

  if (!summary) {
    return <div>no hay reporte</div>;
  }

  return (
    <div>
      <h1 className="text-2xl font-medium mb-4">
        Reporte de ventas de {summary.seller_name}
      </h1>
      <div className="mb-4">
        <SellerSummary summary={summary} customTitle="Resumen General" showViewDetails={false} />
      </div>
      {summary.tickets_summary.length > 0 && (
        <div className="mb-4 overflow-x-auto">
          <h3 className="text-lg font-medium mb-4">Resumen de entradas</h3>
          <TicketsSummaryTable summaries={summary.tickets_summary} />
        </div>
      )}
      <div className="mb-4">
        <TextTitleCard
          title={`Gastos (${formatCurrency(
            summary.transactions_summary.total_amount,
          )})`}
          addButtonAction={
            role === 'ADMIN' || role === 'ROOT'
              ? () => setShowModal(true)
              : undefined
          }
        >
          {summary.transactions_summary.transactions.length > 0 && (
            <TransactionsTable
              transactions={summary.transactions_summary.transactions}
              businessId={businessId}
              eventId={eventId}
            />
          )}
          {summary.transactions_summary.transactions.length === 0 && (
            <div>Este vendedor no tiene gastos</div>
          )}
        </TextTitleCard>
      </div>
      {summary.referral && (
        <div className="mb-4">
          <ReferralSummary referral={summary.referral} />
        </div>
      )}
      {orders.length > 0 && (
        <div>
          <TextTitleCard title="Entradas vendidas">
            <ApprovedOrdersTable orderItems={orders} />
          </TextTitleCard>
        </div>
      )}
      <CustomModal
        onRequestClose={() => setShowModal(false)}
        isOpen={showModal}
        actionBar={<div></div>}
      >
        <Fragment>
          <div className="flex flex-col justify-center items-center mb-5">
            <h1 className="text-xl font-medium mb-4">Agregar movimiento</h1>
            <p className="text-gray-400 text-sm text-center">
              Podrás agregar gastos a este vendedor
            </p>
          </div>
          <AddUserExpense
            businessId={businessId}
            eventId={eventId}
            sellerId={summary.seller_id}
            onSuccess={() => setShowModal(false)}
          />
        </Fragment>
      </CustomModal>
    </div>
  );
};

export default SellersSummaryDetails;
