import { Space } from 'antd';
import Card from 'antd/es/card/Card';
import TicketInfo from './TicketInfo';
import { Text } from './Text';
import TicketDTO from '../models/ticket';

interface NumeratedTicketsEntryProps {
  tickets: TicketDTO[],
}

const NumeratedTicketsEntry = ({ tickets }: NumeratedTicketsEntryProps) => (
    <Space size={16} direction='vertical' style={{ width: '100%' }}>
        <Card className='text-center'>
            <Text size='lg' medium>Rango de precios</Text>
        </Card>
        <Space size={16} direction='vertical' style={{ width: '100%' }}>
            {tickets.map((ticket, index) => (
                <TicketInfo key={index} color={ticket.event_sector?.color ?? ''} title={ticket.event_sector?.name ?? ''} description={ticket.description} price={ticket.price}/>
            ))}
        </Space>
    </Space>
);

export default NumeratedTicketsEntry;
