import React, { useState, useRef, useEffect } from 'react';
import { GoSync } from 'react-icons/go';

interface Option<T> {
  hidden?: boolean;
  label: string;
  onClick: (data: T) => void;
  labelClassName?: string;
}

interface DropdownProps<T> {
  data: T;
  options: Option<T>[];
  loading?: boolean;
  fixed?: boolean;
  label?: string;
  icon?: React.ReactNode;
  className?: string;
}
function MenuDropdown<T>({
  data, options, loading, fixed, label, icon, className,
}: DropdownProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current
      && !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block">
      {loading ? (
        <GoSync className="animate-spin" />
      ) : (
        <button
          className={`cursor-pointer flex items-center gap-1 ${label ? 'px-2 py-1' : 'text-2xl'}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex items-center">
            {label && <span>{label}</span>}
            {icon || (!label && '⋮')}
          </div>
        </button>
      )}
      {isOpen && (
        <div className={`${fixed ? 'fixed' : 'absolute'} z-40 right-0 mt-2 w-32 text-sm bg-white border border-gray-300 rounded shadow-lg ${className}`}>
          <ul className="py-1">
            {options.map((option, index) => (
              !option.hidden && (
                <li key={index}>
                  <a
                    className={`block px-3 py-1 hover:bg-gray-200 cursor-pointer ${option.labelClassName}`}
                    onClick={() => {
                      setIsOpen(false);
                      option.onClick(data);
                    }}
                  >
                    {option.label}
                  </a>
                </li>
              )
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default MenuDropdown;
