export enum EventSeatStatus {
  Available = 'available',
  Unavailable = 'unavailable',
  Reserved = 'reserved',
  Occupied = 'occupied',
}

export interface DisplaySeatDTO {
  id: number;
  status: EventSeatStatus;
  seat_number: number;
  seat_name: string;
  x_coordinate: number;
  y_coordinate: number;
  sector_color: string;
  sector_name: string;
  ticket_price: number;
  ticket_name: string;
  ticket_id: number;
}

export interface ReservationDTO {
  id: number;
  cleared: boolean;
  code: string;
  created_at: string;
  deleted_at: string;
  event_id: number;
  expires_at: string;
  updated_at: string;
}
