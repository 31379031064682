import React, { useState } from 'react';

interface TabOptionsProps {
  tabs: string[];
  defaultTab?: string;
  onTabChange: (tabId: string) => void;
}

const TabOptions: React.FC<TabOptionsProps> = ({ tabs, defaultTab, onTabChange }) => {
  const [activeTabId, setActiveTabId] = useState(defaultTab);
  const handleTabChange = (tab: string) => {
    onTabChange(tab);
    setActiveTabId(tab);
  };

  return (
    <div className="flex align-start self-stretch border border-gray-300 rounded-lg shadow-[0px_0px_0px_1px_rgba(16, 24, 40, 0.18)_inset,0px_-2px_0px_0px_rgba(16,24,40,0.05)_inset,0px_1px_2px_0px_rgba(16,24,40,0.05)]">
      {tabs.map((tab, index) => (
        <div className="flex align-start flex-1">
          <button
            key={index}
            onClick={() => handleTabChange(tab)}
            className={`flex min-w-5 py-2 px-3 justify-center border-r border-gray-300 items-center gap-2 flex-1 ${
              activeTabId === tab
                ? 'text-gray-800 font-medium bg-gray-50'
                : 'text-gray-500 hover:text-gray-700 bg-white'
            } ${index === 0 ? 'rounded-tl-[6px] rounded-bl-[6px]' : ''} ${index === tabs.length - 1 ? 'rounded-tr-[6px] rounded-br-[6px] border-r-0' : ''}`}
          >
            {tab}
          </button>
        </div>
      ))}
    </div>
  );
};

export default TabOptions;
